import { Link, useNavigate } from "react-router-dom";
import styles from "./RecusadosAnunciante.module.css";
import IAnunciante from "../../../../../types/IAnunciante";
import { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../../db/banco";
import axios from "axios";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import guardaPaginaAnterior from "../../../../../stores/paginaAnterior";
import { TextField as T, Autocomplete } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChoiceField from "../../../../../components/ChoiceField";
import { listaFiltroModeradorPorAnunciante } from "../../../../../utils/listas";
import { autocompleteStyles } from "../../../../../utils/styles";

export default function RecusadosAnunciante() {
    const navigate = useNavigate();
    // Aux para atualizar página
    const [aux, setAux] = useState(0);
    const navitate = useNavigate();
    // recebe e controla os influenciadores
    const [anunciantes, setAnunciantes] = useState<IAnunciante[]>([]);
    const useCollectionRefInfluenciador = collection(db, "anunciante");
    const [anunciantesAux, setAnunciantesAux] = useState<IAnunciante[]>([]);
    const [anuncianteFiltrado, setAnuncianteFiltrado] = useState<IAnunciante[]>([]);
    // filtro
    const [Tipofiltro, setTipoFiltro] = useState("");
    const [textoFiltro, setTextoFiltro] = useState("");
    // filtro inteligente
    const [filtrarPor, setFiltrarPor] = useState("");
    const [filtroPorNome, setFiltroPorNome] = useState("");
    const [filtrarPorEmail, setFiltrarPorEmail] = useState("");
    const [filtrarPorRazaoSocial, setFiltroPorRazaoSocial] = useState("");
    // atualizar tabela
    const [atualiza, setAtualiza] = useState(false);
    // obtem os anunciantes
    useEffect(() => {
        const obterInfluenciadores = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const analiseInfluenciaodes: IAnunciante[] = todosInfluenciadores.filter(analise => analise.aceito === "recusado");
            setAnunciantes(analiseInfluenciaodes);
            setAnunciantesAux(analiseInfluenciaodes)

        };
        obterInfluenciadores();
    }, []);

    async function editarAnunciante(id: string, novosDados: any) {
        try {
            if (id) {
                const chamadoDocRef = doc(db, "anunciante", id);

                await updateDoc(chamadoDocRef, novosDados);
                navitate("/moderador/usuarios-recusados");
                setAtualiza(!atualiza);
            }
        } catch (error) {
            alert("Nao deu certo")
        }
    }

    const enviarEmail = async (email: string, nome: string) => {
        try {
            const resposta = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Seu perfil foi aprovado em uma nova triagem!",
                    mensagem: `Oii, ${nome}\n\n` +
                        "Temos uma novidade super legal para te contar!\n\n" +

                        "Você foi aprovado na nossa plataforma!! Incrível, né!?\n\n" +

                        "Periodicamente reavaliamos os perfis cadastrados na nossa plataforma. Realizamos uma\n" +
                        "triagem recente no seu perfil e notamos que ele está cumprindo todos os requisitos para ter\n" +
                        "acesso integral ao ISM\n\n" +

                        "Parabéns, você foi aprovado(a)!\n\n" +

                        "A partir de agora, você poderá criar campanhas\n\n" +

                        "Estamos felizes e animados com nossa parceria!\n\n" +

                        "Conte com a gente para influenciar sempre mais\n" +
                        "Equipe ISM"
                }
            );

            const { id } = resposta.data;
            return id;
        } catch (erro) {
            // console.log(erro);
        }
    };

    async function aceitarAnunciante(id: string, email: string, nome: string) {
        const influenciador = {
            aceito: "aceito"
        }
        await editarAnunciante(id, influenciador);
        enviarEmail(email, nome);
        alert("Anunciante aceito");
    };

    // Paginação

    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };
    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const anunciantesPaginados = anunciantes.slice(startIndex, endIndex);

    // Filtro

    useEffect(() => {
        const guardaPagina = guardaPaginaAnterior.getPagina();
        const convertePagina = Number(guardaPagina);

        if (convertePagina > 0) {
            setPagina(convertePagina);
            setAux(convertePagina);
        }
    }, [aux]);

    // Botão editar
    function irParaPerfilEditar(email: string) {
        navigate(`/moderador/editar-perfil-anunciante?id=${email}&pg=${pagina}`);
    }

    function filtrar() {
        if (filtrarPor === "nome") {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            const recebeFiltro: IAnunciante[] = resultadoFiltrado.filter(porNome => porNome.nomeCompleto === filtroPorNome);
            setAnuncianteFiltrado(recebeFiltro);
        }

        if (filtrarPor === "email") {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            const recebeFiltro: IAnunciante[] = resultadoFiltrado.filter(porNome => porNome.email === filtrarPorEmail);
            setAnuncianteFiltrado(recebeFiltro);
        }

        if (filtrarPor === "razão social") {
            let resultadoFiltrado: IAnunciante[] = [...anunciantesAux];
            const recebeFiltro: IAnunciante[] = resultadoFiltrado.filter(porNome => porNome.razaoSocial === filtrarPorRazaoSocial);
            setAnuncianteFiltrado(recebeFiltro);

            if (recebeFiltro.length === 0) alert("Não existe influenciador com essa categoria aceito na plataforma!");
        }
    }

    return (
        <section className={styles.RecusadosAnunciante}>
            <h3> Filtrar anunciantes por: </h3>

            <aside>
                <ChoiceField
                    title=""
                    control={setFiltrarPor}
                    icon={<FilterAltIcon fontSize="large" />}
                    list={listaFiltroModeradorPorAnunciante}
                    defaultValue="Escolha"
                />

                {filtrarPor === "nome" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={anunciantes.map((option) => option.nomeCompleto)}
                            onInputChange={(event, newInputValue) => {
                                setFiltroPorNome(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Nome do anunciante"
                                    placeholder="Digite o nome"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "email" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={anunciantes.map((option) => option.email)}
                            onInputChange={(event, newInputValue) => {
                                setFiltrarPorEmail(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="E-mail do anunciante"
                                    placeholder="Digite o email"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

                {filtrarPor === "razão social" &&
                    <>
                        <Autocomplete
                            sx={autocompleteStyles}
                            freeSolo
                            options={anunciantes.map((option) => option.razaoSocial)}
                            onInputChange={(event, newInputValue) => {
                                setFiltroPorRazaoSocial(newInputValue);
                            }}
                            renderInput={(params) => (
                                <T

                                    {...params}
                                    label="Razão social do anunciante"
                                    placeholder="Digite a razão social"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <PersonIcon fontSize="large" />
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <button className="btn btn-info btn-lg mb-2 ml-3" onClick={filtrar}> Filtrar </button>

                    </>}

            </aside>

            {anuncianteFiltrado.length > 0 && <h6> Resultado da pesquisa </h6>}

            {anuncianteFiltrado.map((anuncianteEncontrado, index) => (
                <div className="table-responsive">
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                                <th scope="col" className="text-white d-none d-sm-table-cell" style={{ width: '33%' }}>Razão social</th>
                                <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr key={anuncianteEncontrado.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td style={{ width: '33%' }}>
                                    <Link to={`/moderador/ver-perfil-anunciante?id=${anuncianteEncontrado.id}&pg=${pagina}&tp=rec`}>
                                        {anuncianteEncontrado.nomeCompleto}
                                    </Link>
                                </td>
                                <td style={{
                                    width: '33%',
                                    maxWidth: '300px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                                    className="d-none d-sm-table-cell"
                                >
                                    {anuncianteEncontrado.razaoSocial}
                                </td>
                                {/* <td style={{ width: '33%' }}>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-success" onClick={() => aceitarAnunciante(anuncianteEncontrado.id, anuncianteEncontrado.email, anuncianteEncontrado.nomeCompleto)}> Aceitar </button>

                                        <button className="btn btn-info" onClick={() => irParaPerfilEditar(anuncianteEncontrado.email)}>Editar</button>
                                    </div>
                                </td> */}
                                <td className={`${styles.CelulaBotoes} text-wrap`} style={{ width: '33%' }}>
                                    <div className="d-flex justify-content-center justify-content-md-start">
                                        <div className="d-sm-none">
                                            <div className="d-flex flex-column">
                                                <button className="btn btn-success btn-lg mb-2" onClick={() => aceitarAnunciante(anuncianteEncontrado.id, anuncianteEncontrado.email, anuncianteEncontrado.nomeCompleto)}> Aceitar </button>
                                                <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(anuncianteEncontrado.email)}> Editar </button>
                                            </div>
                                        </div>
                                        <div className="d-none d-sm-flex gap-1">
                                            <button className="btn btn-success btn-lg" onClick={() => aceitarAnunciante(anuncianteEncontrado.id, anuncianteEncontrado.email, anuncianteEncontrado.nomeCompleto)}> Aceitar </button>
                                            <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(anuncianteEncontrado.email)}> Editar </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            ))}

            <h6> Lista dos anunciantes em espera </h6>

            {anunciantesPaginados.length > 0 ? <Stack spacing={2}>
                <table className="table table-light table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Nome</th>
                            <th scope="col" className="text-white d-none d-sm-table-cell" style={{ width: '33%' }}>Razão social</th>
                            <th scope="col" className="text-white" style={{ width: '33%' }}>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {anunciantesPaginados.map((cel, index) => (
                            <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <td style={{ width: '33%' }}>
                                    <Link to={`/moderador/ver-perfil-anunciante?id=${cel.id}&pg=${pagina}&tp=rec`}>
                                        {cel.nomeCompleto}
                                    </Link>
                                </td>
                                <td style={{
                                    width: '33%',
                                    maxWidth: '300px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                                    className="d-none d-sm-table-cell"
                                >
                                    {cel.razaoSocial}
                                </td>
                                <td className={`${styles.CelulaBotoes} text-wrap`} style={{ width: '33%' }}>
                                    <div className="d-flex justify-content-center justify-content-md-start">
                                        <div className="d-sm-none">
                                            <div className="d-flex flex-column">
                                                <button className="btn btn-success btn-lg mb-2" onClick={() => aceitarAnunciante(cel.id, cel.email, cel.nomeCompleto)}> Aceitar </button>
                                                <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(cel.email)}> Editar </button>
                                            </div>
                                        </div>
                                        <div className="d-none d-sm-flex gap-1">
                                            <button className="btn btn-success btn-lg" onClick={() => aceitarAnunciante(cel.id, cel.email, cel.nomeCompleto)}> Aceitar </button>
                                            <button className="btn btn-info btn-lg" onClick={() => irParaPerfilEditar(cel.email)}> Editar </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination
                    count={Math.ceil(anunciantes.length / linhasPorPagina)}
                    page={pagina}
                    onChange={handleChangePagina}
                />
            </Stack> : <p> Não existe anunciantes nessa pesquisa </p>}
        </section>

    )
}