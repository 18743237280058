import IInfluenciador from "../../../../types/IInfluenciador";
import { obterTodosInfluenciadores } from "../../../../utils/db";

// Obtém a data de hoje e a data de 7 dias atrás no formato ano-mês-dia (2024-10-11)
export const obterHojeESeteDiasAtras = () => {
    const agora = new Date();

    // Clonamos a data atual para não modificar o objeto original
    const seteDiasAtras = new Date(agora);
    seteDiasAtras.setDate(agora.getDate() - 7);

    const formatarData = (data: Date) => data.toISOString().split('T')[0];

    return {
        hoje: formatarData(agora),
        seteDiasAtras: formatarData(seteDiasAtras)
    };
};
// Obtem o primeiro e último dia do mês. (2024-10-11)
export const obterPrimeiroEUltimoDiaDoMes = () => {
    const agora = new Date();

    // Primeiro dia do mês
    const primeiroDia = new Date(agora.getFullYear(), agora.getMonth(), 1);

    // Último dia do mês
    const ultimoDia = new Date(agora.getFullYear(), agora.getMonth() + 1, 0);

    const formatarData = (data: Date) => data.toISOString().split('T')[0];

    return {
        primeiroDiaDoMes: formatarData(primeiroDia),
        ultimoDiaDoMes: formatarData(ultimoDia)
    };
};

// formtata a data de 2024-08-25 para 25/08/2024
export const formatarData = (data: string): string => {
    return new Date(data).toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};
// Pega o código e retorna o nome
export const obterNomeCompletoPorCodigo = async (codigo: number): Promise<string | null> => {
    const influenciadores = await obterTodosInfluenciadores();
    const influenciadorEncontrado = influenciadores.find(
        influenciador => influenciador.codigoActionpayInfluenciador === codigo
    );

    return influenciadorEncontrado ? influenciadorEncontrado.nomeCompleto : null;
};
// Pega o nome completo e retorna o nome
export const obterCodigoPorNomeCompleto = async (nomeCompleto: string): Promise<number | null> => {
    const influenciadores = await obterTodosInfluenciadores();
    const influenciadorEncontrado = influenciadores.find(
        influenciador => influenciador.nomeCompleto.toLowerCase() === nomeCompleto.toLowerCase()
    );

    return influenciadorEncontrado ? influenciadorEncontrado.codigoActionpayInfluenciador : null;
};
// Pega array de códigos e retorna array de nomes
export const obterNomesPorCodigos = async (codigos: number[]): Promise<string[]> => {
    const influenciadores = await obterTodosInfluenciadores();
    
    const nomesInfluenciadores = codigos.map(codigo => {
        const influenciadorEncontrado = influenciadores.find(
            influenciador => influenciador.codigoActionpayInfluenciador === codigo
        );
        return influenciadorEncontrado ? influenciadorEncontrado.nomeCompleto : '';
    });

    // Filtra nomes vazios caso algum código não tenha sido encontrado
    return nomesInfluenciadores.filter(nome => nome !== '');
};


// Retorna todos os influenciadores aceitos
export const obterInfluenciadoresAceitos = async (): Promise<IInfluenciador[]> => {
    const influenciadores = await obterTodosInfluenciadores();
    const influeciadoresAceitos = influenciadores.filter(somenteAceitos => somenteAceitos.aceito === "aceito");    

    return influeciadoresAceitos;
};
// Verifica se o intervalo de datas é valido (7 dias)
export const verificarIntervaloDeSeteDias = (data1: string, data2: string): boolean => {
    // Convertemos as strings de datas no formato 'ano-mês-dia' para objetos Date
    const primeiraData = new Date(data1);
    const segundaData = new Date(data2);

    // Calculamos a diferença em milissegundos entre as duas datas
    const diferencaEmMilissegundos = Math.abs(primeiraData.getTime() - segundaData.getTime());

    // Convertendo a diferença para dias (1 dia = 24 * 60 * 60 * 1000 milissegundos)
    const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

    // Retorna true se a diferença for de no máximo 7 dias, caso contrário false
    return diferencaEmDias <= 7;
};








