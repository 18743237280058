import React, { ReactNode, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styles from "./popupMini.module.css";

interface InfoPopoverProps {
  triggerImage: string; // Caminho ou URL da imagem a ser usada
  altText: string; // Texto alternativo para a imagem
  content: ReactNode;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({ triggerImage, altText, content }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popover' : undefined;

  return (
    <div className={styles.popupMini}>
      <img
        src={triggerImage}
        alt={altText}
        onClick={handleClick}
        style={{ cursor: 'pointer', width: 'auto', height: '50px' }} // Ajuste o tamanho conforme necessário
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ cursor: 'pointer'}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content}
      </Popover>
    </div>
  );
};

export default InfoPopover;
