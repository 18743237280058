import { useEffect, useState } from "react";
import styles from "./Atualizacao.module.css";
import { Action } from "../../../../interfaces/DadosEstatisticasProps";
import { buscarDadosApiEstatisicas } from "../../../../utils/api";
import { db } from "../../../../db/banco";
import { addDoc, collection, query, getDocs } from "firebase/firestore/lite";
import { obterHojeESeteDiasAtras } from "../../../Common/BuscaEstatisticasAPI/functions";
import Dot from "../../../../assets/Dot";

export default function Atualizacao() {
    // Recebe e trata os dados
    const [actions, setActions] = useState<Action[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    // Pega o da última semana, sendo o máximo de dias que a API permite.
    const { hoje, seteDiasAtras } = obterHojeESeteDiasAtras();

    const useCollectionRef = collection(db, "transactionID");
    // Função para buscar os dados existentes no Firestore
    const fetchExistingActions = async (): Promise<string[]> => {
        const uniqueIdsFromFirestore: string[] = [];
        try {
            const q = query(useCollectionRef); // Busca sem filtros, modifique se necessário
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                uniqueIdsFromFirestore.push(doc.data().uniqueid);
            });
        } catch (error) {
            console.error("Erro ao buscar ações no Firestore: ", error);
        }
        return uniqueIdsFromFirestore;
    };
    
    // Função para salvar apenas as ações que ainda não existem no Firestore
    const saveNewActions = async (actions: Action[]) => {
        try {
            // Busca os uniqueid já existentes no Firestore
            const existingUniqueIds = await fetchExistingActions();
    
            // Filtra as ações novas que não têm o uniqueid presente no Firestore
            const newActions = actions.filter(action => !existingUniqueIds.includes(action.uniqueid));
    
            // Só salva as ações que ainda não estão no Firestore
            if (newActions.length > 0) {
                for (const action of newActions) {
                    await addDoc(useCollectionRef, {
                        uniqueid: action.uniqueid,
                        apid: action.apid,
                        date: action.date,
                        status: {
                            id: action.status.id,
                            name: action.status.name,
                        },
                        offer: action.offer,
                        subId1: action.subIds.subId1, // Acessando corretamente o subId1
                        price: action.price,
                        priceCurrency: action.priceCurrency,
                        payment: action.payment,
                        paymentCurrency: action.paymentCurrency
                    });
                }
                console.log("Novas ações salvas com sucesso!");
            } else {
                console.log("Nenhuma nova ação para salvar.");
            }
        } catch (error) {
            console.error("Erro ao salvar novas ações no Firestore: ", error);
        }
    };
    
    // useEffect para buscar os dados da API e comparar com o Firestore
    useEffect(() => {
        const fetchDataCompleto = async () => {
            try {
                // Busca os dados da API
                const response = await buscarDadosApiEstatisicas(seteDiasAtras, hoje); // Passa apenas os parâmetros obrigatórios
                if (response) {
                    const actions = response.result?.actions || [];
    
                    setActions(actions);  // Acessa response.result.actions de forma segura
    
                    // Verifica e salva apenas as ações novas
                    await saveNewActions(actions);  // Aguarda a função salvar as novas ações
                } else {
                    setError('Erro ao carregar os dados.');
                }
            } catch (error) {
                setError('Erro na chamada da API.');
            } finally {
                setLoading(false);  // Garante que o estado de loading será atualizado
            }
        };
    
        fetchDataCompleto();
    }, []);

    // if (loading) return <p>Carregando...</p>;
    if (loading) return <Dot />;
    // Mostra pro usuário que deu erro.
    if (error) return <p>{error}</p>;


    return (
        <section className={styles.Atualizacao}>
            <h2> Atualização dos links das campanhas de performance e dos transactionID's. </h2>

            <p> Todos os links das campanhas de performance e novos transactionID's serão atualizados todo dia às 1h. </p>

            <p> Atualização manual ativada. </p>

            {/* <table className="table table-light table-striped text-center">
                <thead>
                    <tr>
                        <th> ID da transação </th>
                        <th> Data e hora </th>
                        <th> Status </th>
                        <th> Valor da transação </th>
                        <th> Comissão </th>
                        <th> Campanha </th>
                        <th> Influenciador </th>
                    </tr>
                </thead>
                <tbody>
                    {actions.map((action, index) => (
                        <tr key={action.uniqueid} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td>{action.apid}</td>
                            <td>{(action.date)}</td>
                            <td className={`${action.status.id === 400 ? "desativadoMobile" : action.status.id === 300
                                ? "processando" : "ativoMobile"}`}>{action.status.id === 400 ? "Rejeitada" :
                                    action.status.id === 300 ? "Em espera" : "Aprovada"}
                            </td>
                            <td>{`${action.price} ${action.priceCurrency}`}</td>
                            <td>{`${action.payment} ${action.paymentCurrency}`}</td>                            
                            <td>{(action.offer)}</td>
                            <td>{(action.subIds.subId1)}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}

        </section>
    );
}