import ReactApexChart from 'react-apexcharts';

interface GraphicsProps {
    size: string,
    trial: number,
    action: number,
    premium: number 
}

export default function Graphics({size, trial, action, premium}: GraphicsProps) {
    const categorias = ['Trial', 'Action', 'Premium'];
    const valores = [trial, action, premium];

    const options = {
        labels: categorias.map((categoria, index) => `${categoria}: ${valores[index]}`),
        colors: ['#008FFB', '#00E396', '#FEB019'],
        legend: {
            show: true,
        },
    };

    const series = valores;

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width={size}
        />
    );
};
