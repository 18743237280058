import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface BotaoFiltrosProps {
    mostrarDemaisFiltros: boolean,
    alterarMostrarDemaisFiltros: (value: any) => void
}

export default function BotaoFiltros({mostrarDemaisFiltros, alterarMostrarDemaisFiltros} : BotaoFiltrosProps) {
    return (
        <button className="btn btn-primary" onClick={alterarMostrarDemaisFiltros}>
            {mostrarDemaisFiltros ? (
                <>
                    <RemoveCircleIcon /> Remover filtros
                </>
            ) : (
                <>
                    <AddCircleIcon /> Adicionar filtros
                </>
            )}
        </button>
    )
}