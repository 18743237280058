import { Modal, Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { estiloModalPadrao } from "../../styles";
import styles from "./modals.module.css";
import { Link } from "react-router-dom";

interface ModalsProps {
    openModal: boolean;
    closeModal: () => void;
    title?: string;
    description?: string;    
    cliqueAqui?: boolean;
    children?: ReactNode; // Permitir conteúdo dinâmico
    imageSrc?: string;
}

export default function Modals({ openModal, closeModal, title, description, children, imageSrc, cliqueAqui }: ModalsProps) {
    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={estiloModalPadrao}>
                {title && (
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {description}
                    </Typography>
                )}   
                {cliqueAqui && (
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Clique <Link to="https://influenciesuamarca.com.br/anunciante/inicio" className={styles.Clique}> aqui </Link> para assinar a plataforma.
                    </Typography>
                )}                              
                {imageSrc && (
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <img src={imageSrc} alt="Modal" className={styles.ImagemModal}/>
                    </Typography>
                )}
                <div>
                    {children}
                </div>
            </Box>
        </Modal>
    );
}
