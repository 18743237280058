import CardsAnunciante from "../../../components/CardsPainel";
import styles from "./InicioInfluenciador.module.css";
import campanhasDisponiveis from "./campanhasDisponiveis.png";
import campanhasAtivas from "./campanhasAtivas.png";
import campanhasFinalizadas from "./campanhasFinalizadas.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../db/banco";
import ICriarCampanha from "../../../types/ICriarCampanha";
import autenticaStore from "../../../stores/autentica.store";
import IInfluenciador from "../../../types/IInfluenciador";
import IChatPropostas from "../../../types/ICharPropostas";
import axios from "axios";
import aceitoStore from "../../../stores/aceito";

export default function InicioInfluenciador() {
    const [quantidadeCampanhasDisponiveis, setQuantidadeCampanhasDisponiveis] = useState(0);
    const [quantidadeMinhasCampanhas, setQuantidadeMinhasCampanhas] = useState(0);
    const [quantidadeCampanhasFinalizadas, setQuantidadeCampanhasFinalizadas] = useState(0);
    const [aceito, setAceito] = useState("");
    // nome
    const [nomeCompleto, setNomeCompleto] = useState("");

    // recebe o influenciador
    const [Influenciador, setInfluenciador] = useState<IInfluenciador[]>([]);

    const email = autenticaStore.getEmail();

    // banco de dados
    const useCollectionRef = collection(db, "criarCampanha");

    // puxar dados
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha)

            const campanhasDisponiveis = todasCampanhas.filter(dis => dis.status === "ativa");            
            setQuantidadeCampanhasDisponiveis(campanhasDisponiveis.length)
        }
        obterCampanhas();
    }, [])

    // banco de dados
    const useCollectionRefInfluenciador = collection(db, "influenciador");

    // obtem o influenciador
    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefInfluenciador);
            const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
            const influenciadorEncontrado = todosInfluenciadores.filter(achou => achou.email === email);            
            setInfluenciador(influenciadorEncontrado);
            influenciadorEncontrado.map(aceito => {
                setAceito(aceito.aceito);
                setNomeCompleto(aceito.nomeCompleto)
            })
        };
        obterUsuario();
    }, [])

    // banco de dados
    const useCollectionReff = collection(db, "chatPropostas");

    // puxar dados
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todasCampanhas: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IChatPropostas)
            const campanhasDesseInf: IChatPropostas[] = todasCampanhas.filter(desse => desse.emailInfluenciador === email);
            const somenteAceitas: IChatPropostas[] = campanhasDesseInf.filter(aceitas => aceitas.statusProposta === "aceito");
            const somenteFinalizadas: IChatPropostas[] = campanhasDesseInf.filter(fina => fina.statusProposta === "feito");
           
            setQuantidadeMinhasCampanhas(somenteAceitas.length);
            setQuantidadeCampanhasFinalizadas(somenteFinalizadas.length);
        }
        obterCampanhas();
    }, [])

    useEffect(() => {
        if (aceito) {
            aceitoStore.statusAceito({ aceito: aceito });
        }
    }, [aceito]);

    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Ei, influenciador! Complete o seu cadastro!",
                    mensagem: `Oii, ${nomeCompleto}!\n\n` +

                    "Observamos que o seu perfil está incompleto em nossa plataforma.\n" +
                    "É super importante o preenchimento completo dos seus dados. Quanto mais completinho e\n" +
                    "atualizado, mais chances você tem dos anunciantes te aceitarem nas campanhas. 💜\n\n" +  

                    "Então, vá até a página de “Perfil” no site do ISM, complete seus dados e verifique se caprichou na sua\n" +
                    "foto e no mídia kit!\n\n" +

                    "Assim, seu perfil estará 100% completo e pronto para embarcar em nossas campanhas.\n\n" +
                    
                    "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            
        }
    };

    function enviarEmailUsuarioNaoAceito() {
        enviarEmail();
    }

    return (
        <section className={styles.InicioInfluenciador}>
            <h2> Início </h2>
            {Influenciador.map(anun => (
                <p> Bem-vindo (a) de volta, {anun.nomeCompleto}! </p>
            ))}
            {aceito !== "aceito" && <> <span> Seu perfil ainda não foi aceito, aguarde a análise para interagir em nosso sistema... </span>
                <span> Enquanto isso, aproveite para verificar se preencheu todos os campos de cadastro clicando <Link to="/influenciador/perfil" onClick={enviarEmailUsuarioNaoAceito}> aqui. </Link></span></>}
            <h5> Aqui estao seus dados atualizados. </h5>
            <section>
                <Link to="/influenciador/campanhas-disponiveis">
                    <CardsAnunciante
                        titulo="Campanhas disponíveis"
                        imagem={campanhasDisponiveis}
                        valor={quantidadeCampanhasDisponiveis}
                    />
                </Link>
                <Link to="/influenciador/minhas-campanhas">
                    <CardsAnunciante
                        titulo="Minhas campanhas"
                        imagem={campanhasAtivas}
                        valor={quantidadeMinhasCampanhas}
                    />
                </Link>
                {/* <Link to="/influenciador/minhas-campanhas">
                    <CardsAnunciante
                        titulo="Campanhas em análise"
                        imagem={campanhasAnalise}
                        valor={2}
                    />
                </Link> */}
                <Link to="/influenciador/campanhas-finalizadas">
                    <CardsAnunciante
                        titulo="Campanhas finalizadas"
                        imagem={campanhasFinalizadas}
                        valor={quantidadeCampanhasFinalizadas}
                    />
                </Link>
                {/*
                <Link to="/influenciador/fatura">
                    <CardsAnunciante
                        titulo="Total em sua conta"
                        imagem={dinheiroTres}
                        valor={92.15}
                        dinheiro
                    />
            </Link>*/}
            </section>
        </section>
    );
}