import { Stack, Modal, Box, Typography, Pagination, useMediaQuery } from "@mui/material";
import axios from "axios";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore/lite";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CampoTexto from "../../../../components/CampoTexto";
import { db } from "../../../../db/banco";
import { estiloModalPadrao } from "../../../../styles";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import { listaTiposDeCampanha } from "../../../../utils/listas";
import styles from "./CampanhasPerformanceModerador.module.css";
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditIcon from '@mui/icons-material/Edit';

export default function CampanhasPerformanceModerador() {
    const isMobile = useMediaQuery('(max-width:1000px)');
    const [campanhasComPerformance, setCampanhasComPerformance] = useState<ICriarCampanha[]>([]);
    // controle dos botões de filtro
    // controla o botao clicado
    const [botaoNaoIntegrado, setBotaoNaoIntegrado] = useState("btn btn-outline-secondary");
    const [botaoIntegrado, setBotaoIntegrado] = useState("btn btn-dark");
    const [isIntegrado, setIsIntegrado] = useState(true); // true é para influenciador
    // codigo actionpay
    const [codigoActionpay, setCodigoActionpay] = useState("");
    const [atualiza, setAtualiza] = useState(false);
    // Busca da api
    const [idActionpay, setIdActionpay] = useState("");
    // Add nos favoritos da api
    const [idActionpayAdd, setIdActionpayAdd] = useState("");
    // Controle o modal
    const [open, setOpen] = React.useState(false);
    const [idCampanha, setIdCampanha] = useState("");
    const [qualModalEscolhido, setQualModalEscolhido] = useState(false); // false é para adicionar, true para editar.
    // Contra o status de resposta da API e se abre o inserir em favoritos.
    const [status, setStatus] = useState("");
    const [statusAdd, setStatusAdd] = useState("");
    const [abreInserir, setAbreInserir] = useState(false);

    const useCollectionRef = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as ICriarCampanha));

            const todasCampanhasComPerformance = todasCampanhas.filter(campanhas => campanhas.tipoDeCampanha === listaTiposDeCampanha[2]
                || campanhas.tipoDeCampanha === listaTiposDeCampanha[3] || campanhas.tipoDeCampanha === listaTiposDeCampanha[4]);

            setCampanhasComPerformance(todasCampanhasComPerformance);
        }
        obterCampanhas();

    }, [atualiza]);

    // Paginação
    const [pagina, setPagina] = useState(1);

    const handleChangePagina = (event: any, value: any) => {
        setPagina(value);
    };

    const linhasPorPagina = 10;
    const startIndex = (pagina - 1) * linhasPorPagina;
    const endIndex = startIndex + linhasPorPagina;
    const campanhasPaginados = campanhasComPerformance.slice(startIndex, endIndex);

    // controle dos botões de filtro
    // Funcao que muda o formulario para Influenciador
    function mudaParaIntegrado() {
        setIsIntegrado(true);
        setBotaoIntegrado("btn btn-dark");
        setBotaoNaoIntegrado("btn btn-outline-secondary");
    }
    // Funcao que muda o formulario para Anunciante
    function mudaParaNaoIntegrado() {
        setIsIntegrado(false);
        setBotaoNaoIntegrado("btn btn-dark");
        setBotaoIntegrado("btn btn-outline-secondary");
    }

    async function editarCampanha(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "criarCampanha", idCampanha);

            await updateDoc(criarCampanhaDocRef, novosDados);
            setCodigoActionpay("");
            setAtualiza(!atualiza)
            setOpen(false);
            if (!qualModalEscolhido) alert("Código inserido com sucesso!");
            else alert("Código editado com sucesso!");
        } catch (error) {
            if (!qualModalEscolhido) alert("Código não inserido, atualize a página e tente novamente");
            else alert("Código não editado, atualize a página e tente novamente")!
        }
    }

    function adicionarCodigoActionPay() {
        const campanha = {
            codigoActionpay
        }
        editarCampanha(campanha);
    }

    const fetchDataFromActionpay = async () => {
        const formatoAPI = "json";
        try {
            // Chamada para a função Firebase Functions com os parâmetros
            const response = await axios.get(`https://us-central1-ism-teste.cloudfunctions.net/verificaCampanha?offer=${idActionpay}&format=${formatoAPI}`);
            // Armazenar os dados na variável de estado

            // console.log(response.data);
            // console.log("error", response.data.error);


            if (!response.data.error) {

                setStatus("encontrado");
                setAbreInserir(false);
                setStatusAdd("adicionadoJa")
            } else {

                setStatus("não encontrado");
                setAbreInserir(true);
            }

        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    };

    const fetchAddFavDataFromActionpay = async () => {
        const formatoAPI = "json";
        const act = "add";
        try {
            // Chamada para a função Firebase Functions com os parâmetros
            const response = await axios.get(`https://us-central1-ism-teste.cloudfunctions.net/administrarCampanhas?offer=${idActionpayAdd}&format=${formatoAPI}&act=${act}`);
            // Armazenar os dados na variável de estado
            // console.log("nome do erro", response.data);

            if (!response.data.error) {

                setStatusAdd("adicionada");
                setAbreInserir(false);
                setStatus("adicionadaAgora");
                alert("Campanha adicionada nos favoritos");
            } else {

                setStatusAdd("não adicionada");
                setAbreInserir(true);
                alert("Campanha não encontrada ou já adicionada nos favoritos");
            }

        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    };

    async function buscarNaAPI() {
        if (!idActionpay) {
            alert("Preencha os campos para fazer a busca na API");
            return;
        }
        try {
            await fetchDataFromActionpay(); // Espera até que fetchDataFromActionpay seja concluída            
        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    }

    async function addFavoritosNaAPI() {
        if (!idActionpayAdd) {
            alert("Preencha os campos para fazer a busca na API");
            return;
        }
        try {
            await fetchAddFavDataFromActionpay(); // Espera até que fetchDataFromActionpay seja concluída            
        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    }

    function copiarCodigo(codigo: string) {
        navigator.clipboard.writeText(codigo)
    }

    // Modal
    function abrirModal(idCampanhaVindo: string, qualModal: boolean) {
        setIdCampanha(idCampanhaVindo);
        setQualModalEscolhido(qualModal);
        setOpen(true);
    }

    const fechaModal = () => {
        setOpen(false);
    }

    // Ativar/desativar campanha
    async function ativarCampanha(idCampanha: string) {
        const campanhaCompleta = {
            status: "ativa",
            jaAtivadaAntes: true
        }
        editarStatusCampanha(campanhaCompleta, idCampanha, "ativada");
    }

    async function desativarCampanha(idCampanha: string) {
        const campanhaCompleta = {
            status: "desativada"
        }
        editarStatusCampanha(campanhaCompleta, idCampanha, "desativada");
    }

    async function editarStatusCampanha(novosDados: any, idCampanha: string, mensagem: string) {
        try {
            const criarCampanhaDocRef = doc(db, "criarCampanha", idCampanha);
            await updateDoc(criarCampanhaDocRef, novosDados);
            setAtualiza(!atualiza);
            alert(`Campanha ${mensagem}`);

        } catch (error) {
            alert("Nao deu certo")
        }
    }
    return (
        <section className={styles.CampanhasPerformanceModerador}>
            <h2> Campanhas de performance </h2>
            <p> Aqui estão todas as campanhas que tem performance </p>

            <nav>
                <button type="button" className={botaoIntegrado} onClick={mudaParaIntegrado}> Integrada </button>
                <button type="button" className={botaoNaoIntegrado} onClick={mudaParaNaoIntegrado}> Não integrada </button>
            </nav>

            {campanhasPaginados.length > 0 ? <Stack spacing={2}>
                {!isMobile ? (
                    // Tabela para telas maiores que 1000px
                    <table className="table table-light table-striped text-center">
                        <thead>
                            <tr>
                                <th scope="col" className="text-white"> Nome </th>
                                <th scope="col" className="text-white d-none d-sm-table-cell" style={{ textAlign: 'center' }}> Tipo </th>
                                <th scope="col" className="text-white"> Código Actionpay </th>
                                <th scope="col" className="text-white"> Já integrado? </th>
                                <th scope="col" className="text-white"> Status </th>
                                {isIntegrado && <th scope="col" className="text-white"> Ações </th>}
                            </tr>
                        </thead>
                        <tbody>
                            {campanhasPaginados
                                .filter(cel => isIntegrado ? cel.codigoActionpay !== "" : cel.codigoActionpay === "")
                                .map((cel, index) => (
                                    <tr key={cel.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                        <td className="align-middle text-center">
                                            <Link to={`/moderador/detalhes-campanha-performance?id=${cel.id}`}>
                                                {cel.nomeDaCampanha}
                                            </Link>
                                        </td>
                                        <td className="align-middle text-center">
                                            {cel.tipoDeCampanha}
                                        </td>
                                        <td className="align-middle text-center">
                                            {cel.codigoActionpay ? (
                                                <>
                                                    {cel.codigoActionpay}
                                                    <button onClick={() => copiarCodigo(cel.codigoActionpay)}>
                                                        <ContentCopyIcon />
                                                    </button>
                                                    <button onClick={() => abrirModal(cel.id, true)} className="btn btn-secondary">
                                                        Editar
                                                    </button>
                                                </>
                                            ) : (
                                                <button onClick={() => abrirModal(cel.id, false)} className="btn btn-primary">
                                                    Adicionar
                                                </button>
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {cel.codigoActionpay ? "Sim" : "Não"}
                                        </td>
                                        <td className="align-middle text-center">
                                            <span className={`${cel.status === "ativa" ? "ativoMobile" : "desativadoMobile"}`}>
                                                {cel.status}
                                            </span>
                                        </td>
                                        {isIntegrado && (
                                            <td className="align-middle text-center">
                                                {cel.status === "ativa" ? (
                                                    <CancelOutlinedIcon className={styles.IconeFinalizar} onClick={() => desativarCampanha(cel.id)} />
                                                ) : (
                                                    <CheckCircleOutlineOutlinedIcon className={styles.IconeAtivar} onClick={() => ativarCampanha(cel.id)} />
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                        </tbody>
                        <Modal
                            open={open}
                            onClose={fechaModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={estiloModalPadrao}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {!qualModalEscolhido ? "Adicione o código vindo da plataforma Actionpay."
                                        : "Edite o código vindo da plataforma Actionpay."
                                    }
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <input
                                        value={codigoActionpay}
                                        onChange={(e) => setCodigoActionpay(e.target.value)}
                                    />
                                    <div className={styles.divBotoesModal}>
                                        <button className="btn btn-success" onClick={adicionarCodigoActionPay}> {!qualModalEscolhido ? "Adicionar" : "Editar"} </button>
                                        <button className="btn btn-secondary" onClick={fechaModal}> Voltar </button>
                                    </div>
                                </Typography>
                            </Box>
                        </Modal>
                    </table>
                ) : (
                    // Cards para telas menores que 1000px
                    <div className="card-container">
                        {campanhasPaginados
                            .filter(cel => isIntegrado ? cel.codigoActionpay !== "" : cel.codigoActionpay === "")
                            .map((cel, index) => (
                                <div key={cel.id} className="card mb-3 mt-3" style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Link to={`/moderador/detalhes-campanha-performance?id=${cel.id}`}>
                                                {cel.nomeDaCampanha}
                                            </Link>
                                        </h5>
                                        <p className="card-text"><strong>Tipo:</strong> {cel.tipoDeCampanha}</p>
                                        <p className="card-text">
                                            <strong>Código Actionpay: </strong>
                                            {cel.codigoActionpay ? (
                                                <>
                                                    {cel.codigoActionpay}

                                                    <ContentCopyIcon onClick={() => copiarCodigo(cel.codigoActionpay)} className="ml-2"/>

                                                    <EditIcon onClick={() => abrirModal(cel.id, true)} />

                                                </>
                                            ) : (
                                                <button onClick={() => abrirModal(cel.id, false)} className="btn btn-primary">
                                                    Adicionar
                                                </button>
                                            )}
                                        </p>
                                        <p className="card-text"><strong>Já integrado?</strong> {cel.codigoActionpay ? "Sim" : "Não"}</p>
                                        <p className="card-text">
                                            <strong>Status: </strong>
                                            <span className={`${cel.status === "ativa" ? "ativoMobile" : "desativadoMobile"}`}>
                                                {cel.status}
                                            </span>
                                        </p>
                                        {isIntegrado && (
                                            <div className="card-actions">
                                                {cel.status === "ativa" ? (
                                                    <CancelOutlinedIcon className={styles.IconeFinalizar} onClick={() => desativarCampanha(cel.id)} />
                                                ) : (
                                                    <CheckCircleOutlineOutlinedIcon className={styles.IconeAtivar} onClick={() => ativarCampanha(cel.id)} />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>

                )}

                <Modal
                    open={open}
                    onClose={fechaModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={estiloModalPadrao}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {!qualModalEscolhido ? "Adicione o código vindo da plataforma Actionpay."
                                : "Edite o código vindo da plataforma Actionpay."
                            }
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <input
                                value={codigoActionpay}
                                onChange={(e) => setCodigoActionpay(e.target.value)}
                            />
                            <div className={styles.divBotoesModal}>
                                <button className="btn btn-success" onClick={adicionarCodigoActionPay}> {!qualModalEscolhido ? "Adicionar" : "Editar"} </button>
                                <button className="btn btn-secondary" onClick={fechaModal}> Voltar </button>
                            </div>
                        </Typography>
                    </Box>
                </Modal>

                <Pagination
                    count={Math.ceil(campanhasComPerformance.length / linhasPorPagina)}
                    page={pagina}
                    onChange={handleChangePagina}
                />
            </Stack>
                : <p> Não existe campanhas com performance no momento. </p>}

            <p className={styles.Busca}> Busca na api da Actionpay, insira o código abaixo: </p>

            <aside>
                <CampoTexto
                    titulo="Código da campanha"
                    controle={setIdActionpay}
                    valor={idActionpay}
                    placeholder="15504"
                    icone={<SubtitlesIcon fontSize="large" />}
                />

                {/* <CampoEscolha
                    titulo="Formato"
                    lista={listaFormatosApi}
                    controle={setFormatoAPI}
                    valorPadrao="Escolha"
                    icone={<TryIcon fontSize="large" />}
                /> */}

                <button className="btn btn-info" onClick={buscarNaAPI}> Buscar </button>
            </aside>

            <div className={styles.divStatus}>
                {status === "encontrado" && <p> Status: Campanha já está como favorita na Actionpay. </p>}
                {status === "não encontrado" && <p> Campanha não encontrada, verifique o código, ou adicione essa campanha como favoritos na Actionpay. </p>}
            </div>

            {abreInserir && <aside>
                <CampoTexto
                    titulo="Código da campanha"
                    controle={setIdActionpayAdd}
                    valor={idActionpayAdd}
                    placeholder="15504"
                    icone={<SubtitlesIcon fontSize="large" />}
                />
                <button className="btn btn-primary" onClick={addFavoritosNaAPI}> Adicionar </button>
            </aside>}

            <div className={styles.divStatus}>
                {/*{statusAdd === "adicionada" && <p> Status: Campanha adicionada nos favoritos com sucesso. </p>}*/}
                {statusAdd === "não adicionada" && <p> Campanha não encontrada, verifique o código, ou campanha já adicionada nos favoritos, verique acima. </p>}
            </div>
        </section>
    )
}