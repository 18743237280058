import { useLocation, useNavigate } from "react-router-dom";
import styles from "./CardCarteira.module.css";
import { mascaraDinheiro } from "../../../../utils/mascaras";
import React, { useEffect, useState } from "react";
import ICriarCampanha from "../../../../types/ICriarCampanha";
import AddCardIcon from '@mui/icons-material/AddCard';
import { Modal, Box, Typography } from "@mui/material";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { db } from "../../../../db/banco";
import autenticaStore from "../../../../stores/autentica.store";
import IAnunciante from "../../../../types/IAnunciante";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import IExtratoCampanha from "../../../../types/IExtratoCampanha";
import RefreshIcon from '@mui/icons-material/Refresh';

interface CardCampanhaCarteiraProps {
    saldo: number
}

export default function CardCampanhaCarteira({ saldo }: CardCampanhaCarteiraProps) {
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const statusPagamento = params.get("status");
    // anunciante
    const [idAnunciante, setIdAnunciante] = useState("");
    const [campanhas, setCampanhas] = useState<ICriarCampanha[]>([]);
    const [idParaEditar, setIdParaEditar] = useState("");
    const [saldoAnalise, setSaldoAnalise] = useState(0);
    // estilo do modal
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: 10,

        '@media (max-width: 430px)': {
            width: 350,
        },
    }

    const navigate = useNavigate();
    const [orcamento, setOrcamento] = useState("");
    const [saldoAnterior, setSaldoAnterior] = useState(0);
    const [saldoCarteira, setSaldoCarteira] = useState(0);
    // atualizar o saldo
    const [atualizar, setAtualizar] = useState(true);
    const [atualizarDois, setAtualizarDois] = useState(true);
    const [atualizaTres, setAtualizaTres] = useState(true);
    // Controle do modal
    const [open, setOpen] = React.useState(false);
    // alerta de saldo
    const [alertaSaldo, setAlertaSaldo] = useState(false);
    const [alertaSaldoInferior, setAlertaSaldoInferior] = useState(false);
    // para calcular o valor gasto em campanhas
    const [valorGastoEmCampanhas, setValorGastoEmCampanhas] = useState(0);
    // Extrato campanha    
    const [nomeCampanha, setNomeCampanha] = useState("");
    const [extratos, setExtratos] = useState<IExtratoCampanha[]>([]);

    useEffect(() => {
        console.log("parametro", statusPagamento);

        if (statusPagamento === "approved" && saldoAnalise !== 0) {
            adicionarNoSaldoEmAnalise();
        }

    }, [statusPagamento, saldoAnalise])


    function adicionarNoSaldoEmAnalise() {
        const novoSaldo = Number(saldoCarteira) + Number(saldoAnalise);
        const anunciante = {
            saldo: novoSaldo,
            saldoEmAnalise: 0,

        }
        editarAnunciante(anunciante);
    }

    const fechaModal = () => {
        setOpen(false);
        setAlertaSaldo(false);
        setAlertaSaldoInferior(false);
        setAtualizar(!atualizar);
    }

    const abreModal = (id: string, saldoAntes: number, nome: string) => {
        setOpen(true);
        setAtualizar(!atualizar)
        setAtualizarDois(!atualizarDois);
        setAtualizaTres(!atualizaTres);
        setOrcamento("");
        setIdParaEditar(id);
        setSaldoAnterior(saldoAntes);
        setNomeCampanha(nome)
    }

    function adicionarOrcamento() {
        // alert(saldoAnterior)   
        setAtualizar(!atualizar);
        setAtualizarDois(!atualizarDois);
        setAtualizaTres(!atualizaTres);

        let orcamentoLimpo = orcamento.replace(/[^\d,]/g, '');

        if (Number(orcamentoLimpo) < 100) {
            alert("Orçamento mínimo de R$100,00");
            setAlertaSaldoInferior(true);
            return;
        }

        if (Number(orcamentoLimpo) > saldoCarteira) {
            alert("O orçamento está maior que seu saldo!");
            setAlertaSaldo(true);
            setAtualizar(!atualizar);

        } else {
            setAlertaSaldo(false);

            // cáculo do orçamento de cada campanha
            let enviarOrcamento = saldoAnterior + Number(orcamentoLimpo);
            // saldo da carteira
            let enviarSaldo = saldoCarteira - Number(orcamentoLimpo);

            // alert(`${saldoCarteira} - ${Number(orcamentoLimpo)} = ${enviarSaldo}`)

            const campanha = {
                saldoCampanha: enviarOrcamento
            }

            const anunciante = {
                saldo: enviarSaldo
            }

            editarCampanha(campanha);
            editarAnunciante(anunciante);
            criarExtrato();

            setAtualizar(!atualizar);
            setAtualizarDois(!atualizarDois);
            setAtualizaTres(!atualizaTres);
            alert("Saldo adicionado com sucesso!");
        }
    }

    async function editarCampanha(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "criarCampanha", idParaEditar);

            await updateDoc(criarCampanhaDocRef, novosDados);
            fechaModal();

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    async function editarAnunciante(novosDados: any) {
        try {
            const criarCampanhaDocRef = doc(db, "anunciante", idAnunciante);

            await updateDoc(criarCampanhaDocRef, novosDados);
            fechaModal();

        } catch (error) {
            alert("Nao deu certo")
        }
    }

    // campanhas
    const useCollectionReff = collection(db, "criarCampanha");
    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhasFiltradas: ICriarCampanha[] = todasCampanhas.filter(filtrado => filtrado.anuncianteEmail === email);
            setCampanhas(campanhasFiltradas);
            // recebe e calcula valor gasto em campanhas
            const recebeOrcamentos = campanhasFiltradas.map(valor => valor.saldoCampanha);
            let soma = recebeOrcamentos.reduce(function (acumulador, elemento) {
                return acumulador + elemento;
            }, 0)
            setValorGastoEmCampanhas(soma);

            console.log(saldoCarteira);


            console.log("campanhas:", campanhasFiltradas);
            setAtualizarDois(!atualizarDois);
        }
        obterCampanhas();
    }, [atualizar]);

    const useCollectionRef = collection(db, "anunciante");
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const filtrado: IAnunciante[] = todosAnunciantes.filter(filtrado => filtrado.email === email);
            console.log(filtrado);
            filtrado.map(esseAnunciante => {
                setSaldoCarteira(esseAnunciante.saldo);
                setIdAnunciante(esseAnunciante.id);
                setSaldoAnalise(esseAnunciante.saldoEmAnalise);
            })
        }
        setAtualizaTres(!atualizaTres)
        obterAnunciantes();
    }, [atualizarDois]);

    function irParaAdicionarSaldo() {
        navigate("/anunciante/adicionar-saldo")
    }

    // banco de dados
    const useCollectionRefff = collection(db, "extratoCampanha");
    useEffect(() => {
        const obterExtratos = async () => {
            const dataBD = await getDocs(useCollectionRefff);
            const todasCampanhas: IExtratoCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IExtratoCampanha);
            const filtrado: IExtratoCampanha[] = todasCampanhas.filter(filtrado => filtrado.email === email);



            console.log("Extratos:", filtrado);

            filtrado.sort((a, b) => {
                // Comparação pela data
                const dataA = new Date(a.data);
                const dataB = new Date(b.data);
                if (dataA > dataB) return -1;
                if (dataA < dataB) return 1;

                // Se as datas forem iguais, compare pela hora
                if (a.hora > b.hora) return -1;
                if (a.hora < b.hora) return 1;

                return 0; // Se forem iguais
            });

            setExtratos(filtrado);


        }
        obterExtratos();
    }, [atualizaTres]);


    async function criarExtrato() {
        const dataAtual = new Date();
        const data = dataAtual.toLocaleDateString();
        const horaCompleta = dataAtual.toLocaleTimeString('pt-BR');

        const extrato = await addDoc(useCollectionRefff, {
            email,
            nomeCampanha,
            data: data,
            hora: horaCompleta,
            valor: orcamento,
            positivo: true,
        })
        setAtualizaTres(!atualizaTres);
        console.log(extrato);
    }

    function refresh() {
        setAtualizar(!atualizar);
        setAtualizarDois(!atualizarDois);
        setAtualizaTres(!atualizaTres);
    }

    return (
        <section className={styles.ContainerPrincipal}>
            <div className={styles.CardCarteira}>
                <header><p>Carteira</p></header>

                <aside>
                    <section className={styles.PrimeiroSection}>
                        <p>Saldo disponível:</p>
                        <p>Saldo em campanhas:</p>
                    </section>

                    <section className={styles.SegundoSection}>
                        <p>R$ {saldoCarteira},00</p>
                        <p>R$ {valorGastoEmCampanhas},00</p>
                    </section>
                </aside>

                <nav>
                    <button onClick={irParaAdicionarSaldo}>
                        <AccountBalanceWalletIcon fontSize="medium" />
                        Adicionar saldo
                    </button>
                    <button onClick={refresh} className={styles.Refresh}>
                        <RefreshIcon fontSize="large" />
                    </button>
                </nav>
            </div>

            <div className={styles.CardCarteiraSaldo}>
                <header><p>Saldo das campanhas</p></header>

                {campanhas.map((campanha, index) => (
                    <aside key={index}>
                        <section className={styles.PrimeiroSectionSaldo}>
                            <p>{campanha.nomeDaCampanha}</p>
                        </section>

                        <Modal open={open} onClose={fechaModal} aria-labelledby="modal-title" aria-describedby="modal-description">
                            <Box sx={style}>
                                <Typography id="modal-title" variant="h6">Seu saldo é de: R$ {saldoCarteira},00</Typography>
                                <Typography id="modal-description" sx={{ mt: 2 }}>
                                    Adicione orçamento na campanha {nomeCampanha}. Esse valor será debitado de seu saldo.
                                    <input value={mascaraDinheiro(orcamento)} onChange={(e) => setOrcamento(e.target.value)} className={styles.InputModal} />
                                    {alertaSaldo && <p className={styles.AlertaSaldo}>Saldo insuficiente para esse orçamento!</p>}
                                    {alertaSaldoInferior && <p className={styles.AlertaSaldo}>Orçamento mínimo de R$100,00</p>}

                                    <div className={styles.divBotoesModal}>
                                        <button className="btn btn-success" onClick={adicionarOrcamento}>Adicionar</button>
                                        <button className="btn btn-secondary" onClick={fechaModal}>Voltar</button>
                                    </div>
                                </Typography>
                            </Box>
                        </Modal>

                        <section className={styles.SegundoSection}>
                            <p>
                                R$ {campanha.saldoCampanha},00
                                <AddCardIcon onClick={() => abreModal(campanha.id, campanha.saldoCampanha, campanha.nomeDaCampanha)} />
                            </p>
                        </section>
                    </aside>
                ))}
            </div>

            <div className={styles.CardCarteiraExtrato}>
                <header><p>Extrato das campanhas</p></header>

                {extratos.map(extrato => (
                    <aside key={extrato.id}>
                        <section className={styles.PrimeiroSectionExtrato}><p>{extrato.nomeCampanha}</p></section>
                        <section className={styles.PrimeiroSection}><p>{extrato.data}</p></section>
                        <section className={styles.SegundoSectionExtrato}>
                            <p className={extrato.positivo ? styles.Verde : styles.Vermelho}>
                                {extrato.positivo ? "+" : "-"} {extrato.valor},00
                            </p>
                        </section>
                    </aside>
                ))}
            </div>
        </section>

    )
}