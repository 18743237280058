import React from 'react';
import { ITransactionID } from '../../../../types/ITransactionID';

interface Action {
  payment: number;
  priceCurrency: string;
  status: string;
}

interface TotalProps {
  transactions: ITransactionID[];
}

const Total: React.FC<TotalProps> = ({ transactions }) => {
  // Calcula a soma por moeda e status "Aprovada"
  const totalAprovadaBRL = transactions
    .filter((action) => action.priceCurrency === 'BRL' && action.status.id === 1)
    .reduce((acc, action) => acc + action.payment, 0);

  const totalAprovadaUSD = transactions
    .filter((action) => action.priceCurrency === 'USD' && action.status.id === 1)
    .reduce((acc, action) => acc + action.payment, 0);

  const totalAprovadaEUR = transactions
    .filter((action) => action.priceCurrency === 'EUR' && action.status.id === 1)
    .reduce((acc, action) => acc + action.payment, 0);

  // Calcula a soma por moeda e status.id "Em espera"
  const totalEmEsperaBRL = transactions
    .filter((action) => action.priceCurrency === 'BRL' && action.status.id === 300)
    .reduce((acc, action) => acc + action.payment, 0);

  const totalEmEsperaUSD = transactions
    .filter((action) => action.priceCurrency === 'USD' && action.status.id === 300)
    .reduce((acc, action) => acc + action.payment, 0);

  const totalEmEsperaEUR = transactions
    .filter((action) => action.priceCurrency === 'EUR' && action.status.id === 300)
    .reduce((acc, action) => acc + action.payment, 0);

  return (
    <div>      
      <table className="table table-light table-striped text-center">
        <thead>
          <tr >
            <th>Status</th>
            <th>Total em real</th>
            <th>Total em dólar</th>
            <th>Total em euro</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ color: 'green' , fontWeight: 'bold'}}>Aprovada</td>
            <td>
              R$
              {totalAprovadaBRL.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              $
              {totalAprovadaUSD.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              €
              {totalAprovadaEUR.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
          <tr style={{ backgroundColor: '#ffffff' }}>
            <td style={{ color: 'orange' , fontWeight: 'bold'}}>Em espera</td>
            <td>
              R$
              {totalEmEsperaBRL.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              $
              {totalEmEsperaUSD.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              €
              {totalEmEsperaEUR.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Total;
