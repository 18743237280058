import { TextField as T, Autocomplete } from '@mui/material';
import { autocompleteStyles } from '../../utils/styles';
import { ReactNode, useEffect, useState } from 'react';
import styles from "./SmartFilterInfluenciador.module.css";

interface SmartFilterInfluenciadorProps {
    list: string[],
    onInputChange: (value: string) => void;
    icon: ReactNode;
}

export default function SmartFilterInfluenciador({ list, onInputChange, icon }: SmartFilterInfluenciadorProps) {
    const [inputValue, setInputValue] = useState('');

    // Recupera o último nome usado do localStorage ao montar o componente
    useEffect(() => {
        const savedInputValue = localStorage.getItem('ultimoNomeInfluenciador');
        if (savedInputValue) {
            setInputValue(savedInputValue);
            onInputChange(savedInputValue); // Chama o onInputChange com o valor salvo
        }
    }, []);

    // Atualiza o localStorage e o estado do input
    const handleInputChange = (event: any, newInputValue: string) => {
        setInputValue(newInputValue);
        localStorage.setItem('ultimoNomeInfluenciador', newInputValue); // Salva o valor no localStorage
        onInputChange(newInputValue);
    };

    return (
        <Autocomplete
            className={styles.SmartFilter}
            sx={autocompleteStyles}
            freeSolo
            options={list.map((option) => option)}
            value={inputValue} // Define o valor do Autocomplete como o inputValue
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <T
                    {...params}
                    label="Nome do Influenciador"
                    placeholder="Digite o nome do influenciador"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {icon}
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
