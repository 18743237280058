import { Link } from "react-router-dom";
import CardsAnunciante from "../../../components/CardsPainel";
import GerenciamentoDeInfluenciadores from "./GerenciamentoDeInfluenciadores";
import styles from "./InicioAnunciante.module.css";
import campanhaImagem from "./campanhas.png";
import dinheioDois from "./dinheiroDois.png";
import autenticaStore from "../../../stores/autentica.store";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../db/banco";
import { useEffect, useState } from "react";
import IAnunciante from "../../../types/IAnunciante";
import ICriarCampanha from "../../../types/ICriarCampanha";
import IChatPropostas from "../../../types/ICharPropostas";
import axios from "axios";
import aceitoStore from "../../../stores/aceito";
import { listaTiposDeCampanha } from "../../../utils/listas";
import VerificaPLano from "../VerificaPlano";

export default function InicioAnunciante() {
    const [aceito, setAceito] = useState("");
    // Preencher gráfico
    const [influenciadoresAtivos, setInfluenciadoresAtivos] = useState(0);
    const [influenciadoresAnalise, setInfluenciadoresAnalise] = useState(0);
    const [influenciadoresFinalizados, setInfluenciadoresFinalizados] = useState(0);
    // banco de dados
    const useCollectionRefAnunciante = collection(db, "anunciante");
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    // recebe, se tem campanha com performance aguardando
    const [temCampanhaPerformanceAguardando, setTemCampanhaPerformanceAguardando] = useState(0);
    // Nome
    const [nomeCompleto, setNomeCompleto] = useState("");
    // recebe o anunciante
    const [anunciante, setAnunciante] = useState<IAnunciante[]>([]);
    const [saldo, setSaldo] = useState(0);
    // Plano
    const [plano, setPlano] = useState(0);
    const [dataCriacao, setDataCriacao] = useState("");
    const [dataPlanoTrocado, setDataPlanoTrocado] = useState("");
    // Recebe e contrapa as campanhas ativas
    const [qtdCampanhasAtivas, setQtdCampanhasAtivas] = useState(0);
    // obtem o anunciante

    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefAnunciante);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const anuncianteEncontrado = todosAnunciantes.filter(achou => achou.email === email);            
            setAnunciante(anuncianteEncontrado);
            anuncianteEncontrado.map(anunciante => {
                setAceito(anunciante.aceito);
                setNomeCompleto(anunciante.nomeCompleto);
                setSaldo(anunciante.saldo);
                setPlano(anunciante.planoISM);
                setDataCriacao(anunciante.dataCriacao);
                setDataPlanoTrocado(anunciante.planoTrocado);
            });
        };
        obterUsuario();
    }, [])

    const useCollectionRef = collection(db, "criarCampanha");

    // filtra pelas campanhas do email
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);
            const campanhasDesseAnunciante = todasCampanhas.filter(cam => cam.anuncianteEmail === email);            
            const somenteAtivas = campanhasDesseAnunciante.filter(atv => atv.status === "ativa");
            setQtdCampanhasAtivas(somenteAtivas.length);

            const todasCampanhasComPerformance = todasCampanhas.filter(campanhas => campanhas.tipoDeCampanha === listaTiposDeCampanha[2]
                || campanhas.tipoDeCampanha === listaTiposDeCampanha[3] || campanhas.tipoDeCampanha === listaTiposDeCampanha[4]);
            
            const todasCampanhasComPerformanceEsperandoIntegracao = todasCampanhasComPerformance.filter(campanha => campanha.jaIntegrada === false);
            setTemCampanhaPerformanceAguardando(todasCampanhasComPerformanceEsperandoIntegracao.length);

           
            
        }
        obterAnunciantes();
    }, [])

    // banco de dados
    const useCollectionReff = collection(db, "chatPropostas");
    // pegar dados para preencher o grafico de influenciadores
    useEffect(() => {
        const obterChat = async () => {
            // pega o chat proposta certo
            const dataBD = await getDocs(useCollectionReff);
            const todosChamados: IChatPropostas[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id } as IChatPropostas))

            

            const somenteDesseAnunciante: IChatPropostas[] = todosChamados.filter(somente => somente.emailAnunciante === email);
            const ativoChamados: IChatPropostas[] = somenteDesseAnunciante.filter(ativos => ativos.statusProposta !== "espera");
            
            // ativos
            let listaAtivos = [""];
            ativoChamados.forEach(atv => {
                listaAtivos.push(atv.nomeInfluenciador);
            })
            let arraySemVazios = listaAtivos.filter(elemento => elemento !== "");
            arraySemVazios = [...new Set(arraySemVazios)];
            setInfluenciadoresAtivos(arraySemVazios.length)
            // analise
            const emEsperaChamados: IChatPropostas[] = somenteDesseAnunciante.filter(emEspera => emEspera.statusProposta === "espera");
            let listaEspera: string[] = [];
            emEsperaChamados.forEach(espera => {
                listaEspera.push(espera.nomeInfluenciador)
            })
            listaEspera = [...new Set(listaEspera)];
            setInfluenciadoresAnalise(listaEspera.length)
            // finalizado
            const finalizadoChamados: IChatPropostas[] = somenteDesseAnunciante.filter(ativos => ativos.statusProposta === "feito");
            let listaFinalizado: string[] = [];
            finalizadoChamados.forEach(fin => {
                listaFinalizado.push(fin.nomeInfluenciador);
            })
            listaFinalizado = [...new Set(listaFinalizado)];
            setInfluenciadoresFinalizados(listaFinalizado.length);
        }
        obterChat();

    }, []);

    const status = aceitoStore.getStatus();

    useEffect(() => {
        if (aceito) {

            aceitoStore.statusAceito({ aceito: aceito });


        }
    }, [aceito]);


    const enviarEmail = async () => {
        try {
            const response = await axios.post(
                "https://us-central1-ism-teste.cloudfunctions.net/enviarEmail",
                {
                    destinatario: email,
                    assunto: "Ei, anunciante, complete o seu cadastro!",
                    mensagem: `Oii, ${nomeCompleto}!\n\n` +

                        "Observamos que o seu perfil está incompleto em nossa plataforma.\n" +
                        "É super importante o preenchimento completo dos seus dados.\n\n" +

                        "Então, vá até a página de “Perfil” no site do ISM, complete os dados da sua empresa\n\n" +

                        "Assim, seu perfil estará 100% completo e pronto para criar campanhas e receber propostas\n" +
                        "dos influenciadores.\n\n" +

                        "Equipe ISM"
                }
            );

            const { id } = response.data;
            return id;
        } catch (error) {
            // console.log(error);
        }
    };

    function enviarEmailUsuarioNaoAceito() {
        enviarEmail();
    }

    return (
        <section className={styles.InicioAnunciante}>
            <h2> Início </h2>
            {anunciante.map(anun => (
                <p> Bem-vindo (a) de volta, {anun.nomeCompleto}! </p>                
            ))}
            {temCampanhaPerformanceAguardando > 0 && <p> Você recebeu um e-mail com instruções referente a campanha com performance criada. </p>}
            {aceito !== "aceito" && <> <span> Seu perfil ainda não foi aceito, aguarde para interagir em nosso sistema... </span>
                <span> Complete seu perfil, clicando <Link to="/anunciante/perfil" onClick={enviarEmailUsuarioNaoAceito}> aqui </Link>.</span></>}

            <h5> Aqui estao seus dados atualizados. </h5>

            <h6> Seu plano atual é: <strong> {plano === 0 ? "Trial." : plano === 1 ? "Action." : "Premium."} </strong> </h6>

            <h6> {plano === 0 ? <> São 30 dias para testar nossa plataforma. Ou se preferir antecipe sua assinatura <strong> clicando aqui. </strong> </> 
                : plano === 1 ? "Acesso ilimitado a nossa plataforma." 
                : "Acesso ilimitado a nossa plataforma."} 
            </h6>

            <VerificaPLano plano={plano} dataCriacao={dataCriacao} planoTrocado={dataPlanoTrocado} email={email} nome={nomeCompleto}/>

            {/* {plano === 0 && <VerificaPLano plano={plano}/>} */}
            <section>
                <Link to="/anunciante/minhas-campanhas">
                    <CardsAnunciante
                        titulo="Campanhas ativas"
                        imagem={campanhaImagem}
                        valor={qtdCampanhasAtivas}
                    />
                </Link>
                {/*
                <Link to="/anunciante/minhas-campanhas">
                    <CardsAnunciante
                        titulo="Budget total em conta"
                        imagem={dinheiro}
                        valor={saldo}
                        dinheiro
                    />
            </Link>*/}
                <Link to="/anunciante/carteira">
                    <CardsAnunciante
                        titulo="Budget total em conta"
                        imagem={dinheioDois}
                        valor={saldo}
                        dinheiro
                    />
                </Link>
                <Link to="/anunciante/influenciadores-em-analise">
                    <GerenciamentoDeInfluenciadores ativos={influenciadoresAtivos} analise={influenciadoresAnalise} finalizados={influenciadoresFinalizados} />
                </Link>
            </section>
        </section>
    )
}