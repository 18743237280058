export const listaTiposDeCampanha = [
    'Publipost',
    'Publipost + permuta',
    'Publipost + performance',
    'Permuta + performance',
    'Performance'
    // índices [0, 1] sem performance
    // índices [2, 3, 4] com performance

    // índices [1, 3] com permuta
]

export const listaTiposDeObjetivos = [
    'Venda confirmada',
    'Cadastro',
    'Inscrição',
    'Primeiro depósito',
    'Instalação'
]

export const listaFormatosApi = [
    'json',
    'xml'
]

export const listaDeStatusCampanhasAnunciante = [
    'ativa',    
    'desativada',    
    'todas',
];

export const listaTipoDeMaterialDaCampanha = [
    'Instagram da marca',
    'Site da marca',
    'Facebook da marca'
];

export const listaDeFiltroAgrupamentoEstatisticasDeCampanha = [
    'transação',
    // 'influenciador',    
] // transação 0, influenciador 1

export const listaFiltroEstatisticasDeCampanha = [
    'influenciador'
]

export const listaFiltroModeradorPorInfluenciador = [
    'nome',
    'email',
    'categoria'
]

export const listaFiltroModeradorPorAnunciante = [
    'nome',    
    'email',
    'razão social'
]

export const listaFiltroModeradorOrdenarPorNumeroDeSeguidores = [
    'mais seguidores',
    'menos seguidores',    
] // 0 decrscente, 1 crescente

export const listaPlanosISM = [
    'Trial',
    'Action',
    'Premium'
]