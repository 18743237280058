import { BrowserRouter, Routes, Route } from "react-router-dom";
import PaginaInicial from "./pages/Visitante/PaginaInicial";
import Faq from "./pages/Visitante/Faq";
import PoliticaEPrivacidade from "./pages/Visitante/PoliticaEPrivacidade";
import TermosDeUso from "./pages/Visitante/TermosDeUso";
import Login from "./pages/Visitante/Login";
import Cadastrar from "./pages/Visitante/Cadastrar";
import PaginaBaseRotaPublicaVisitante from "./pages/Visitante/PaginaBaseRotaPublicaVisitante";
import PaginaBaseRotaPrivadaAnunciante from "./pages/Anunciante/PaginaBaseRotaPrivadaAnunciante";
import PaginaBaseRotaPrivadaInfluenciador from "./pages/Influenciador/PaginaBaseRotaPrivadaInfluenciador";
import InicioAnunciante from "./pages/Anunciante/InicioAnunciante";
import InicioInfluenciador from "./pages/Influenciador/InicioInfluenciador";
import CriarCampanha from "./pages/Anunciante/CriarCampanha";
import MinhasCampanhas from "./pages/Anunciante/MinhasCampanhas";
import InfluenciadoresEmAnalise from "./pages/Anunciante/InfluenciadoresEmAnalise";
import AguardandoConfirmacao from "./pages/Anunciante/AguardandoConfirmacao";
import NotaFiscal from "./pages/Anunciante/NotaFiscal";
import CampanhasDisponiveis from "./pages/Influenciador/CampanhasDisponiveis";
import Propostas from "./pages/Influenciador/Propostas";
import MinhasCampanhasInfluenciador from "./pages/Influenciador/MinhasCampanhasInfluenciador";
import CampanhasFinalizadas from "./pages/Influenciador/CampanhasFinalizadas";
import Fatura from "./pages/Influenciador/Fatura";
import RedesSociais from "./pages/Influenciador/RedesSociais";
import EstatisticasDeCampanha from "./pages/Anunciante/EstatisticasDeCampanha";
import PropostasAnunciante from "./pages/Anunciante/PropostasAnunciante";
import FinalizarTrabalho from "./pages/Anunciante/FinalizarTrabalho";
import PerfilAnunciante from "./pages/Anunciante/PerfilAnunciante";
import PerfilInfluenciador from "./pages/Influenciador/PerfilInfluenciador";
import DetalhesCampanha from "./pages/Influenciador/CampanhasDisponiveis/DetalhesCampanha";
import PaginaBasePrivadaModerador from "./pages/Administrador/Moderador/PaginaBasePrivadaModerador";
import InicioModerador from "./pages/Administrador/Moderador/InicioModerador";
import AceitarUsuarios from "./pages/Administrador/Moderador/AceitarUsuarios";
import EditarUsuarios from "./pages/Administrador/Moderador/EditarUsuarios";
import EditarCampanhas from "./pages/Administrador/Moderador/EditarUsuarios/EditarAnunciantes/VerCampanhasModerador";
import UsuariosRecusados from "./pages/Administrador/Moderador/UsuariosRecusados";
import AbrirChamadoAnunciante from "./pages/Anunciante/AbrirChamadoAnunciante";
import AbrirChamadoInfluenciador from "./pages/Influenciador/AbrirChamadoInfluenciador";
import MeusChamadosAnunciante from "./pages/Anunciante/MeusChamadosAnunciante";
import ChatAnuncianteSup from "./pages/Anunciante/ChatAnuncianteSup";
import PaginaBasePrivadaSuporte from "./pages/Administrador/Suporte/PaginaBasePrivadaSuporte";
import MeusChamadosInfluenciador from "./pages/Influenciador/MeusChamadosInfluenciador";
import ChatInfluenciadorSup from "./pages/Influenciador/ChatInfluenciadorSup";
import CriarImagemCampanha from "./pages/Anunciante/CriarImagemCampanha";
import MeusChamadosSuporte from "./pages/Administrador/Suporte/MeusChamadosSuporte";
import InicioSuporte from "./pages/Administrador/Suporte/InicioSuporte";
import VerPerfil from "./pages/Administrador/Suporte/VerPerfil";
import ChatSuporte from "./pages/Administrador/Suporte/ChatSuporte";
import RotaPrivada from "./utils/RotaPrivada";
import EditarCampanhaAnunciante from "./pages/Anunciante/EditarCampanhaAnunciante";
import EditarImagemCampanhaAnunciante from "./pages/Anunciante/EditarImagemCampanhaAnunciante";
import PaginaBasePrivadaFinanceiro from "./pages/Administrador/Financeiro/PaginaBasePrivadaFinanceiro";
import InicioFinanceiro from "./pages/Administrador/Financeiro/InicioFinanceiro";
import EnviarTrabalho from "./pages/Influenciador/EnviarTrabalho";
import FinalizarTrabalhoDoInfluenciador from "./pages/Anunciante/FinalizarTrabalhoDoInfluenciador";
import Carteira from "./pages/Anunciante/Carteira";
import LoginAdm from "./pages/Visitante/LoginAdm";
import LeituraInfluenciador from "./common/SomenteLeituraPerfil/LeituraInfluenciador";
import LeituraAnunciante from "./common/SomenteLeituraPerfil/LeituraAnunciante";
import SomenteLeituraCampanhas from "./common/SomenteLeituraCampanhas";
import VisualizarCampanha from "./common/SomenteLeituraCampanhas/VisualizarCampanha";
import MeusChamadosFinanceiro from "./pages/Administrador/Financeiro/MeusChamadosFinanceiro";
import ChatFinanceiro from "./pages/Administrador/Financeiro/ChatFinanceiro";
import VerPerfilFinanceiro from "./pages/Administrador/Financeiro/VerPerfilFinanceiro";
import EnviarNotaFiscalInflu from "./pages/Administrador/Financeiro/EnviarNotaFiscalInflu";
import LeituraInfluenciadorMod from "./pages/Administrador/Moderador/SomenteLeituraPerfilMod/LeituraInfluenciadorMod";
import LeituraAnuncianteMod from "./pages/Administrador/Moderador/SomenteLeituraPerfilMod/LeituraAnuncianteMod";
import EditarPerfilInfluenciador from "./pages/Administrador/Moderador/EditarPerfilInfluenciador";
import EditarPerfilAnunciante from "./pages/Administrador/Moderador/EditarPerfilAnunciante";
import EditarCampanhaModerador from "./pages/Administrador/Moderador/EditarCampanhaModerador";
import EditarImagemCampanhaModerador from "./pages/Administrador/Moderador/EditarImagemCampanhaModerador";
import AdicionarSaldo from "./pages/Anunciante/Carteira/AdicionarSaldo";
import VerPerfilDoInfluenciador from "./pages/Anunciante/VerPerfilDoInfluenciador";
import CriarNotaFiscalCPF from "./pages/Influenciador/CriarNotaFiscalCPF";
import LeituraInfluenciadorFinanceiro from "./pages/Administrador/Financeiro/SomenteLeituraPerfilFinanceiro/LeituraInfluenciadorFinanceiro";
import LeituraAnuncianteFinanceiro from "./pages/Administrador/Financeiro/SomenteLeituraPerfilFinanceiro/LeituraAnuncianteFinanceiro";
import VerNotaFiscal from "./pages/Administrador/Financeiro/VerNotaFiscal";
import RotaPrivadaFinanceiro from "./utils/RotaPrivadaFinanceiro";
import RotaPrivadaModerador from "./utils/RotaPrivadaModerador";
import RotaPrivadaSuporte from "./utils/RotaPrivadaSuporte";
import UsuarioNaoAceitoAnunciante from "./pages/UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import UsuarioNaoAceitoInfluenciador from "./pages/UsuarioNaoAceito/UsuarioNaoAceitoInfluenciador";
import DetalhesCampanhaPerformance from "./pages/Administrador/Moderador/InicioModerador/DetalhesCampanhaPerformance";
import NotificacoesAnunciante from "./pages/Anunciante/NotificacoesAnunciante";
import NotificacoesInfluenciador from "./pages/Influenciador/NotificacoesInfluenciador";
import CampanhasPerformanceModerador from "./pages/Administrador/Moderador/CampanhasPerformanceModerador";
import RecicladosUsuarios from "./pages/Administrador/Moderador/RecicladosUsuarios";
import Atualizacao from "./pages/Administrador/Moderador/Atualizacao";
import AceitosUsuarios from "./pages/Administrador/Moderador/AceitosUsuarios";
import TabelaParaCopiar from "./pages/Administrador/Moderador/TabelaParaCopiar";
import NovoChat from "./pages/Common/NovoChat";
import EstatisticasDeCampanhaInfluenciador from "./pages/Influenciador/EstatisticasDeCampanhaInfluenciador";
import EstatisticasDeCampanhaModerador from "./pages/Administrador/Moderador/EstatisticasDeCampanhaModerador";
import EstatisticasDeCModerador from "./pages/Administrador/Moderador/EstatisticasDeCampanhaModerador/EstatisticasDeCModerador";
import PlanosAnunciantes from "./pages/Administrador/Moderador/PlanosAnunciantes";
import BuscaEstatisticasAPI from "./pages/Common/BuscaEstatisticasAPI";

export default function AppRoute() {
    return (
        <BrowserRouter>
            {/* Rota publica */}
            {/* Rota de visitantes */}
            <Routes>
                <Route path="/" element={<PaginaBaseRotaPublicaVisitante />}>
                    <Route index element={<PaginaInicial />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/politica-e-privacidade" element={<PoliticaEPrivacidade />} />
                    <Route path="/termos-de-uso" element={<TermosDeUso />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/cadastrar" element={<Cadastrar />} />
                    <Route path="/login-administrativo" element={<LoginAdm />} />
                </Route>
            </Routes>
            {/* Rotas Privadas */}
            {/* Rota de anunciante */}
            <Routes>
                <Route element={<RotaPrivada />}>
                    <Route path="/anunciante/" element={<PaginaBaseRotaPrivadaAnunciante />}>
                        <Route index path="/anunciante/inicio" element={<InicioAnunciante />} />
                        <Route path="/anunciante/perfil" element={<PerfilAnunciante />} />
                        <Route path="/anunciante/criar-imagem-campanha" element={<CriarImagemCampanha />} /> 
                        <Route path="/anunciante/criar-campanha" element={<CriarCampanha />} />
                        <Route path="/anunciante/editar-campanha" element={<EditarCampanhaAnunciante />} /> 
                        <Route path="/anunciante/editar-imagem-campanha" element={<EditarImagemCampanhaAnunciante />} />
                        <Route path="/anunciante/minhas-campanhas" element={<MinhasCampanhas />} /> 
                        <Route path="/anunciante/estatisticas-de-campanha" element={<EstatisticasDeCampanha />} /> {/*Pelo postback */}
                        <Route path="/anunciante/estatisticas-da-campanha" element={<BuscaEstatisticasAPI />} />  {/*Pela API */}
                        <Route path="/anunciante/influenciadores-em-analise" element={<InfluenciadoresEmAnalise />} /> 
                        <Route path="/anunciante/propostas-anunciante" element={<PropostasAnunciante />} /> 
                        <Route path="/anunciante/aguardando-confirmacao" element={<AguardandoConfirmacao />} /> 
                        <Route path="/anunciante/finalizar-trabalho" element={<FinalizarTrabalho />} />
                        <Route path="/anunciante/nota-fiscal" element={<NotaFiscal />} /> 
                        <Route path="/anunciante/abrir-chamado" element={<AbrirChamadoAnunciante />} /> 
                        <Route path="/anunciante/meus-chamados" element={<MeusChamadosAnunciante />} />
                        <Route path="/anunciante/chat-suporte" element={<ChatAnuncianteSup />} />
                        <Route path="/anunciante/finalizar-trabalho-influenciador" element={<FinalizarTrabalhoDoInfluenciador />} /> 
                        <Route path="/anunciante/carteira" element={<Carteira />} />
                        <Route path="/anunciante/adicionar-saldo" element={<AdicionarSaldo />} /> 
                        <Route path="/anunciante/perfil-influenciador" element={<VerPerfilDoInfluenciador />} />
                        <Route path="/anunciante/notificacoes" element={<NotificacoesAnunciante />} />
                        <Route path="/anunciante/anunciante-nao-aceito" element={<UsuarioNaoAceitoAnunciante />} />                        
                        <Route path="/anunciante/chat-proposta" element={<NovoChat />} />
                    </Route>
                </Route>
            </Routes>
            {/* Rota Influenciador a*/}
            <Routes>
                <Route element={<RotaPrivada />}>
                    <Route path="/influenciador/" element={<PaginaBaseRotaPrivadaInfluenciador />}>
                        <Route path="/influenciador/perfil" element={<PerfilInfluenciador />} />
                        <Route index path="/influenciador/inicio" element={<InicioInfluenciador />} />
                        <Route path="/influenciador/campanhas-disponiveis" element={<CampanhasDisponiveis />} />
                        <Route path="/influenciador/propostas" element={<Propostas />} /> 
                        <Route path="/influenciador/detalhes-campanha" element={<DetalhesCampanha />} /> 
                        <Route path="/influenciador/propostas" element={<Propostas />} />
                        <Route path="/influenciador/minhas-campanhas" element={<MinhasCampanhasInfluenciador />} /> 
                        <Route path="/influenciador/estatisticas-de-campanha" element={<EstatisticasDeCampanhaInfluenciador />} /> {/*Pela postback */}
                        <Route path="/influenciador/estatisticas-da-campanha" element={<BuscaEstatisticasAPI />} />  {/*Pela API */}
                        <Route path="/influenciador/campanhas-finalizadas" element={<CampanhasFinalizadas />} /> 
                        <Route path="/influenciador/fatura" element={<Fatura />} /> 
                        <Route path="/influenciador/redes-sociais" element={<RedesSociais />} /> 
                        <Route path="/influenciador/abrir-chamado" element={<AbrirChamadoInfluenciador />} /> 
                        <Route path="/influenciador/meus-chamados" element={<MeusChamadosInfluenciador />} /> 
                        <Route path="/influenciador/chat-suporte" element={<ChatInfluenciadorSup />} /> 
                        <Route path="/influenciador/enviar-trabalho" element={<EnviarTrabalho />} /> 
                        <Route path="/influenciador/criar-nota-fiscal" element={<CriarNotaFiscalCPF />} />
                        <Route path="/influenciador/notificacoes" element={<NotificacoesInfluenciador />} /> 
                        <Route path="/influenciador/influenciador-nao-aceito" element={<UsuarioNaoAceitoInfluenciador />} />
                        <Route path="/influenciador/chat-proposta" element={<NovoChat />} />
                    </Route>
                </Route>
            </Routes>

            {/* Rota Moderador */}
            <Routes>
                <Route element={<RotaPrivadaModerador />}>
                    <Route path="/moderador/" element={<PaginaBasePrivadaModerador />}>
                        <Route index path="/moderador/inicio" element={<InicioModerador />} />
                        <Route path="/moderador/aceitar-usuarios" element={<AceitarUsuarios />} />
                        <Route path="/moderador/usuarios-aceitos" element={<AceitosUsuarios />} />
                        <Route path="/moderador/usuarios-recusados" element={<UsuariosRecusados />} />
                        <Route path="/moderador/usuarios-reciclados" element={<RecicladosUsuarios />} />
                        <Route path="/moderador/atualizacao" element={<Atualizacao />} />
                        <Route path="/moderador/editar-usuarios" element={<EditarUsuarios />} />
                        <Route path="/moderador/ver-camapanhas" element={<EditarCampanhas />} />
                        <Route path="/moderador/editar-perfil-anunciante" element={<EditarPerfilAnunciante />} />
                        <Route path="/moderador/editar-perfil-influenciador" element={<EditarPerfilInfluenciador />} />
                        <Route path="/moderador/ver-perfil-influenciador" element={<LeituraInfluenciadorMod />} />
                        <Route path="/moderador/ver-perfil-anunciante" element={<LeituraAnuncianteMod />} />  
                        <Route path="/moderador/editar-campanha" element={<EditarCampanhaModerador />} />  
                        <Route path="/moderador/editar-imagem-campanha" element={<EditarImagemCampanhaModerador />} /> 
                        <Route path="/moderador/detalhes-campanha-performance" element={<DetalhesCampanhaPerformance />} />                      
                        <Route path="/moderador/campanhas-performance" element={<CampanhasPerformanceModerador />} />   
                        <Route path="/moderador/desenvolvedor" element={<TabelaParaCopiar />} />    
                        <Route path="/moderador/estatistica-de-campanha" element={<EstatisticasDeCampanhaModerador />} /> 
                        <Route path="/moderador/ver-estatisticas-de-campanha" element={<EstatisticasDeCModerador />} />  {/*Pela postback */}    
                        <Route path="/moderador/estatisticas-da-campanha" element={<BuscaEstatisticasAPI />} />  {/*Pela API */}        
                        <Route path="/moderador/planos-anunciantes" element={<PlanosAnunciantes />} />              
                    </Route>
                </Route>
            </Routes>

            {/* Rota Suporte */}
            <Routes>
                <Route element={<RotaPrivadaSuporte />}>
                    <Route path="/suporte/" element={<PaginaBasePrivadaSuporte />}>
                        <Route index path="/suporte/inicio" element={<InicioSuporte />} />
                        <Route path="/suporte/meus-chamados" element={<MeusChamadosSuporte />} />
                        <Route path="/suporte/ver-perfil" element={<VerPerfil />} />
                        <Route path="/suporte/chat-usuario" element={<ChatSuporte />} />
                        <Route path="/suporte/ver-influenciador" element={<LeituraInfluenciador />} />
                        <Route path="/suporte/ver-anunciante" element={<LeituraAnunciante />} />
                        <Route path="/suporte/ver-campanhas" element={<SomenteLeituraCampanhas />} /> 
                        <Route path="/suporte/visualizar-campanha" element={<VisualizarCampanha />} />                       
                    </Route>
                </Route>
            </Routes>

            {/* Rota Financeiro */}
            <Routes>
                <Route element={<RotaPrivadaFinanceiro />}>
                    <Route path="/financeiro/" element={<PaginaBasePrivadaFinanceiro />}>
                        <Route index path="/financeiro/inicio" element={<InicioFinanceiro />} />
                        <Route path="/financeiro/meus-chamados" element={<MeusChamadosFinanceiro />} />
                        <Route path="/financeiro/chat-financeiro" element={<ChatFinanceiro />} />
                        <Route path="/financeiro/ver-perfil" element={<VerPerfilFinanceiro />} />
                        <Route path="/financeiro/nota-fiscal-anunciante" element={<EnviarNotaFiscalInflu />} />
                        <Route path="/financeiro/nota-fiscal-influenciador" element={<VerNotaFiscal />} />
                        <Route path="/financeiro/ver-perfil-influenciador" element={<LeituraInfluenciadorFinanceiro />} />
                        <Route path="/financeiro/ver-perfil-anunciante" element={<LeituraAnuncianteFinanceiro />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}