import styles from "./CriarCampanha.module.css";
import { useEffect, useState } from "react";
import StepperPasso from "../../../components/StepperPasso";
import CampoTexto from "../../../components/CampoTexto";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CampoEscolha from "../../../components/CampoEscolha";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import BotaoStepper from "../../../components/BotaoStepper";
import { listaDeNichos } from "../../../utils/listaDeNichos";
import PolylineIcon from '@mui/icons-material/Polyline';
import AreaTexto from "../../../components/AreaTexto";
import { listaDeGeneros } from "../../../utils/listaDeGeneros";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import CampoSwitch from "../../../components/CampoSwitch";
import { listaQtdDeSeguidores } from "../../../utils/listaQtdDeSeguidores";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import { listaDeCategorias } from "../../../utils/listaDeCategorias";
import MapIcon from '@mui/icons-material/Map';
import { estados } from "../../../utils/apiIbge";
import { listaDeIdadesFim, listaDeIdadesInicio } from "../../../utils/listaDeIdades";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { addDoc, collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../../db/banco";
import autenticaStore from "../../../stores/autentica.store";
import { v4 as uuidv4 } from 'uuid';
import IAnunciante from "../../../types/IAnunciante";
import UsuarioNaoAceitoAnunciante from "../../UsuarioNaoAceito/UsuarioNaoAceitoAnunciante";
import aceitoStore from "../../../stores/aceito";
import { listaTipoDePerformance } from "../../../utils/tipoDePerformance";
import ChecklistIcon from '@mui/icons-material/Checklist';
import { mascaraDinheiro, mascaraPorcentagem } from "../../../utils/mascaras";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FlagIcon from '@mui/icons-material/Flag';
import { enviarIntrucoesCampanhaComPerformance } from "../../../utils/email";
import ICriarCampanha from "../../../types/ICriarCampanha";
import { listaTipoDeMaterialDaCampanha, listaTiposDeCampanha, listaTiposDeObjetivos } from "../../../utils/listas";
import RedeemIcon from '@mui/icons-material/Redeem';
import { limparValor } from "./functions";

export default function CriarCampanha() {
    // verifica se usuário foi aceito na plataforma
    const statusPlataforma = aceitoStore.getStatus();
    // anunciante da campanha
    const [nomeDoAnunciante, setNomeDoAnunciante] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    // Pegar parametro da página
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const parametro = params.get("id"); // Parâmetro da página
    // Controlar stepper
    const [step, setStep] = useState(0)
    // Gera o id do storage
    const idStorage = uuidv4();
    // Sobre a campanha
    const [nomeDaCampanha, setNomeDaCampanha] = useState("");
    const [tipoDeCampanha, setTipoDeCampanha] = useState("");
    const [inicioInscricao, setInicioInscricao] = useState("");
    const [fimInscricao, setFimInscricao] = useState("");
    // Material
    const [tipoPerformance, setTipoPerformance] = useState("");
    const [valorFixoSujo, setValorFixoSujo] = useState("");
    const [valorVariavelSujo, setValorVariavelSujo] = useState("");
    const [objetivo, setObjetivo] = useState("");
    const [tipoDeMaterial, setTipoDeMaterial] = useState("");
    const [nichos, setNichos] = useState("");
    const [link, setLink] = useState("");
    const [textoPermuta, setTextoPermuta] = useState("");
    const [descreva, setDescreva] = useState("");
    const [cupom, setCupom] = useState("");
    const [descontoCupom, setDescontoCupom] = useState("");
    // Perfil do influenciador
    const [genero, setGenero] = useState("");
    const [switchGenero, setSwitchGenero] = useState(false);
    //
    const [qtdSeguidores, setQtdSeguidores] = useState("");
    const [switchQtdSeguidores, setSwitchQtdSeguidores] = useState(false)
    //
    const [categoria, setCaegoria] = useState("");
    const [switchCategoria, setSwitchCaegoria] = useState(false);
    const [categorias, setCategorias] = useState<string[]>([]);

    //
    const [idadeInicio, setIdadeInicio] = useState("");
    const [idadeFim, setIdadeFim] = useState("");
    const [switchIdade, setSwitchIdade] = useState(false);
    //
    const [estado, setEstado] = useState("");
    const navigate = useNavigate();
    // codigo
    const [quantidadeCampanhas, setQuantidadeCampanhas] = useState(0);
    // plano  


    const nomesEstados = estados.map((estado) => estado.nome);
    nomesEstados.push(" Brasil");
    nomesEstados.sort();

    // banco de dados
    const useCollectionRef = collection(db, "criarCampanha");

    const email = autenticaStore.getEmail();

    const status = aceitoStore.getStatus();

    // banco de dados
    const useCollectionReff = collection(db, "anunciante");

    useEffect(() => {
        const obterCampanha = async () => {
            const dataBD = await getDocs(useCollectionReff);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante)

            const anuncianteEncontrado: IAnunciante[] = todosAnunciantes.filter(dis => dis.email === email);
            
            anuncianteEncontrado.map(esse => {
                setNomeDoAnunciante(esse.nomeCompleto);
                setRazaoSocial(esse.razaoSocial);
            })

            
        }
        obterCampanha();
    }, []);

    useEffect(() => {
        const obterCampanhas = async () => {
            const dataBD = await getDocs(useCollectionRef);
            const todasCampanhas: ICriarCampanha[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ICriarCampanha);

            setQuantidadeCampanhas(todasCampanhas.length);
        }
        obterCampanhas();
    }, []);

    useEffect(() => {
        if (parametro) {
            // buscar no banco de dados e abrir para editar
            

        } else {
            // criar nova camapanha
            
        }
    }, [parametro])


    useEffect(() => {
        if (categoria !== '') {
            // Verifica se a categoria já existe no array
            if (!categorias.includes(categoria)) {
                setCategorias(prevCategorias => [...prevCategorias, categoria]);
            }
        }
    }, [categoria, categorias]);

    const removerCategoria = (index: number) => {
        const novasCategorias = [...categorias];
        novasCategorias.splice(index, 1); // Remove o elemento do array usando o índice
        setCategorias(novasCategorias);

        // Atualiza o estado com o novo array sem o item removido
        
    };

    async function criarCampanha(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // Limpar números
        const valorFixoSemCifrao = extrairNumeros(valorFixoSujo);
        const valorFixo = Number(valorFixoSemCifrao);
        
        const valorVariavel = limparValor(valorVariavelSujo);
        const campanhaCompleta = await addDoc(useCollectionRef, {
            idStorage,
            anuncianteEmail: email,
            nomeAnunciante: nomeDoAnunciante,
            razaoSocial,
            status: "desativada",
            // Sobre a campanha
            nomeDaCampanha,
            tipoDeCampanha,
            inicioInscricao,
            fimInscricao,
            // Material
            tipoPerformance,
            valorFixo,
            valorVariavel,
            objetivo,
            tipoDeMaterial,
            nichos,
            link,
            textoPermuta,
            descreva,
            cupom,
            descontoCupom,
            // Perfil do influenciador
            genero,
            switchGenero,
            //
            qtdSeguidores,
            switchQtdSeguidores,
            //
            categorias,
            switchCategoria,
            //
            idadeInicio,
            idadeFim,
            switchIdade,
            //
            estado,
            saldoCampanha: 0,
            // status
            jaIntegrada: false,
            jaAtivadaAntes: false,
            // codigo
            codigoIntegracao: 1001 + quantidadeCampanhas,
            codigoActionpay: ""
        })
        if (tipoDeCampanha === listaTiposDeCampanha[2] || tipoDeCampanha === listaTiposDeCampanha[3]
            || tipoDeCampanha === listaTiposDeCampanha[4]) {
                criarNovaNotificacao();
            }
            
        alert("Agora, vamos criar sua imagem!");
        navigate(`/anunciante/criar-imagem-campanha?id=${idStorage}`)

        if (tipoDeCampanha === "Performance" || tipoDeCampanha === "Modelo Híbrido") {
            enviarIntrucoesCampanhaComPerformance(email, nomeDoAnunciante);
        }
    }

    function avancaStep() {
        setStep(step + 1);
    }

    function voltaStep() {
        setStep(step - 1);
    }

    const extrairNumeros = (texto: string) => {
        return texto.replace(/\D/g, ''); // Substitui tudo que não for dígito por uma string vazia
    };

    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    async function criarNovaNotificacao() {
        const notificacao = await addDoc(useCollectionRefNotificacao, {
            mensagem: "Você criou uma campanha de performance, verifique seu e-mail para receber as instruções.",
            visto: false,
            tipoDoUsuarioQueVaiReceberNotificacao: "anunciante",
            emailDeQuemVaiReceberNotificacao: email,
            data: new Date().toLocaleDateString(),
            link: "https://influenciesuamarca.com.br/anunciante/inicio"
        })
    }

    return (
        <>
            {status === "aceito" ? <main className={styles.CriarCampanha}>
                <h2> Criar campanha </h2>

                <StepperPasso passo={step} />

                <form onSubmit={criarCampanha}>
                    {step === 0 ? <div>
                        <CampoTexto
                            titulo="Nome da campanha"
                            valor={nomeDaCampanha}
                            controle={(valor) => setNomeDaCampanha(valor)}
                            icone={<DriveFileRenameOutlineIcon fontSize="large" />}
                            placeholder="Riot Games"
                            minLength={3}
                        />
                        <div className={styles.ContainerAlertas}>
                            {!nomeDaCampanha && <span> Preencha esse campo </span>}
                            {nomeDaCampanha.length < 3 ? <span> Número mínimo de caracteres: 3 </span> : ""}
                        </div>

                        <CampoEscolha
                            lista={listaTiposDeCampanha}
                            titulo="Tipo de campanha"
                            controle={setTipoDeCampanha}
                            icone={<WrapTextOutlinedIcon fontSize="large" />}
                            valorPadrao={tipoDeCampanha ? tipoDeCampanha : "Escolha o tipo"}
                        />
                        {tipoDeCampanha.length === 0 ? <span> Preencha esse campo </span> : ""}

                        <CampoTexto
                            titulo="Início da inscrição"
                            valor={inicioInscricao}
                            controle={(valor) => setInicioInscricao(valor)}
                            icone={<EventNoteIcon fontSize="large" />}
                            type="date"
                        />
                        {!inicioInscricao && <span> Preencha esse campo </span>}

                        <CampoTexto
                            titulo="Fim da incrição"
                            valor={fimInscricao}
                            controle={(valor) => setFimInscricao(valor)}
                            icone={<EventBusyOutlinedIcon fontSize="large" />}
                            type="date"
                        />
                        <div className={styles.ContainerAlertas}>
                            {!fimInscricao && <span> Preencha esse campo </span>}

                            <button
                                onClick={avancaStep}
                                className="btn btn-secondary"
                                disabled={!nomeDaCampanha || tipoDeCampanha.length === 0 || !inicioInscricao ||
                                    !fimInscricao || nomeDaCampanha.length < 3}
                            >
                                Próximo
                            </button>
                            {!nomeDaCampanha || tipoDeCampanha.length === 0 || !inicioInscricao || !fimInscricao ?
                                <span> Preencha os campos para avançar </span> :
                                ""}

                        </div>
                    </div> : ''}

                    {step === 1 ? <div>

                        {tipoDeCampanha === listaTiposDeCampanha[2] || tipoDeCampanha === listaTiposDeCampanha[3]
                            || tipoDeCampanha === listaTiposDeCampanha[4] ?
                            <>
                                <CampoEscolha
                                    lista={listaTipoDePerformance}
                                    titulo="Selecione o tipo de performance"
                                    controle={setTipoPerformance}
                                    icone={<ChecklistIcon fontSize="large" />}
                                    valorPadrao={tipoPerformance ? tipoPerformance : "Selecione entre os tipos"}
                                />

                                {tipoPerformance.length === 0 && <span> Preencha esse campo </span>}

                                {tipoPerformance === "Valor fixo" &&
                                    <CampoTexto
                                        titulo='Valor'
                                        valor={mascaraDinheiro(valorFixoSujo)}
                                        controle={setValorFixoSujo}
                                        icone={<AttachMoneyIcon fontSize='large' />}
                                        placeholder="R$ 100"
                                    />
                                }

                                {tipoPerformance === "Valor fixo" && valorFixoSujo.length === 3 && <span> Preencha esse campo </span>}

                                {tipoPerformance === "Valor fixo" && valorFixoSujo.length === 0 && <span> Preencha esse campo </span>}


                                {tipoPerformance === "Valor variável" && (
                                    <>
                                        <CampoTexto
                                            titulo='Porcentagem'
                                            valor={valorVariavelSujo}
                                            controle={setValorVariavelSujo}
                                            icone={<AttachMoneyIcon fontSize='large' />}
                                            placeholder="10%"
                                        />
                                        {valorVariavelSujo[0] === "0" && <span> Exemplo válido: 12,5% </span>}
                                        {valorVariavelSujo.length === 0 || valorVariavelSujo[0] === "%" ? <span> Preencha esse campo </span> : ""}   
                                                                          
                                    </>
                                )}

                                <CampoEscolha
                                    titulo="Objetivo"
                                    lista={listaTiposDeObjetivos}
                                    controle={setObjetivo}
                                    valorPadrao={objetivo ? objetivo : "Escolha"}
                                    icone={<FlagIcon fontSize="large" />}

                                />

                                {objetivo.length === 0 && <span> Preencha esse campo </span>}

                            </>
                            : ""}

                        <CampoEscolha
                            lista={listaTipoDeMaterialDaCampanha}
                            titulo="Tipo de material"
                            controle={setTipoDeMaterial}
                            icone={<SwitchAccountIcon fontSize="large" />}
                            valorPadrao="Escolha entre os tipos"
                        />
                        {tipoDeMaterial.length === 0 ? <span> Preencha esse campo </span> : ""}

                        <CampoEscolha
                            lista={listaDeNichos}
                            titulo="Nicho"
                            controle={setNichos}
                            icone={<PolylineIcon fontSize="large" />}
                            valorPadrao="Selecione o seu"
                        />
                        {nichos.length === 0 ? <span> Preencha esse campo </span> : ""}

                        <CampoTexto
                            titulo="Link"
                            valor={link}
                            controle={(valor) => setLink(valor)}
                            icone={<AddLinkIcon fontSize="large" />}
                            placeholder="instagram.com/riotgames/"
                        />
                        {!link && <span> Preencha esse campo </span>}

                        {tipoDeCampanha === listaTiposDeCampanha[1] || tipoDeCampanha === listaTiposDeCampanha[3] ?
                            <CampoTexto
                                titulo="O que deseja enviar pro influenciador?"
                                controle={setTextoPermuta}
                                valor={textoPermuta}
                                placeholder="Uma Ring Light"
                                icone={<RedeemIcon fontSize="large" />}
                            />
                            : ""
                        }

                        <AreaTexto titulo="Descreva" valor={descreva} controle={setDescreva} />

                        <CampoTexto
                            titulo="Cupom"
                            placeholder="LUIZA20"
                            valor={cupom}
                            controle={setCupom}
                            minLength={3}
                            icone={<LocalActivityIcon fontSize="large" />}
                        />

                        {/* <div className={styles.ContainerAlertas}>
                        {!cupom && <span> Preencha esse campo </span>}
                        {cupom.length < 3 ? <span> Número mínimo de caracteres: 3 </span> : ""}
                    </div> */}

                        <CampoTexto
                            titulo=" Desconto do cupom"
                            placeholder="20%"
                            valor={descontoCupom}
                            controle={setDescontoCupom}
                            minLength={2}
                            icone={<ConfirmationNumberIcon fontSize="large" />}
                        />

                        {/* <div className={styles.ContainerAlertas}>
                        {!descontoCupom && <span> Preencha esse campo </span>}
                        {descontoCupom.length < 2 ? <span> Número mínimo de caracteres: 2 </span> : ""}
                    </div> */}
                        <div className={styles.ContainerAlertas}>
                            {/* {!descreva && <span> Preencha esse campo </span>} */}

                            {tipoDeCampanha === listaTiposDeCampanha[2] || tipoDeCampanha === listaTiposDeCampanha[3]
                                || tipoDeCampanha === listaTiposDeCampanha[4] ?
                                <>
                                    {tipoPerformance === "Valor fixo" ?
                                        <>
                                            {tipoDeMaterial.length === 0 || nichos.length === 0 || !link ||
                                                tipoPerformance.length === 0 || valorFixoSujo.length === 3 || tipoPerformance.length === 0
                                                || objetivo.length === 0 || valorFixoSujo.length === 0 ?
                                                <span> Preencha os campos para avançar </span> :
                                                ""}
                                        </>

                                        :

                                        <>
                                            {tipoDeMaterial.length === 0 || nichos.length === 0 || !link ||
                                                tipoPerformance.length === 0 || tipoPerformance.length === 0
                                                || valorVariavelSujo[0] === "0" || valorVariavelSujo.length === 0 || valorVariavelSujo[0] === "%"
                                                || objetivo.length === 0 ?
                                                <span> Preencha os campos para avançar </span> :
                                                ""}
                                        </>

                                    }
                                </>
                                :


                                <>
                                    {tipoDeMaterial.length === 0 || nichos.length === 0 || !link ?
                                        <span> Preencha os campos para avançar </span> :
                                        ""}

                                </>
                            }


                        </div>

                        <section>
                            <BotaoStepper controle={voltaStep}>
                                Anterior
                            </BotaoStepper>

                            {tipoDeCampanha === "Performance" || tipoDeCampanha === "Modelo Híbrido" ?
                                <>
                                    {tipoPerformance === "Valor fixo" ?
                                        <button
                                            onClick={avancaStep}
                                            className="btn btn-secondary"
                                            disabled={tipoDeMaterial.length === 0 || nichos.length === 0 || !link ||
                                                tipoPerformance.length === 0 || valorFixoSujo.length === 3 || tipoPerformance.length === 0
                                                || objetivo.length === 0
                                            }
                                        >
                                            Próximo
                                        </button>
                                        :
                                        <button
                                            onClick={avancaStep}
                                            className="btn btn-secondary"
                                            disabled={tipoDeMaterial.length === 0 || nichos.length === 0 || !link ||
                                                tipoPerformance.length === 0 || tipoPerformance.length === 0
                                                || valorVariavelSujo[0] === "0" || valorVariavelSujo.length === 0 || valorVariavelSujo[0] === "%"
                                                || objetivo.length === 0
                                            }
                                        >
                                            Próximo
                                        </button>

                                    }

                                </>
                                :
                                <button
                                    onClick={avancaStep}
                                    className="btn btn-secondary"
                                    disabled={tipoDeMaterial.length === 0 || nichos.length === 0 || !link}
                                >
                                    Próximo
                                </button>
                            }

                        </section>
                    </div> : ''}

                    {step === 2 ? <div>

                        <CampoEscolha
                            lista={listaDeGeneros}
                            titulo="Gênero"
                            icone={<PeopleOutlineOutlinedIcon fontSize="large" />}
                            valorPadrao="Escolha seu gênero"
                            controle={setGenero}
                        />
                        {genero.length === 0 ? <span> Preencha esse campo </span> : ""}

                        <CampoSwitch
                            titulo="Aceita fora do escolhido?"
                            valor={switchGenero}
                            controle={setSwitchGenero}
                        />

                        <CampoEscolha
                            lista={listaQtdDeSeguidores}
                            titulo="Quantidade de seguidores"
                            icone={<GroupsOutlinedIcon fontSize="large" />}
                            valorPadrao="Número de seguidores que aceita"
                            controle={setQtdSeguidores}
                        />
                        {qtdSeguidores.length === 0 ? <span> Preencha esse campo </span> : ""}

                        <CampoSwitch
                            titulo="Aceita fora do escolhido?"
                            valor={switchQtdSeguidores}
                            controle={setSwitchQtdSeguidores}
                        />

                        <CampoEscolha
                            lista={listaDeCategorias}
                            titulo="Categoria do seu influenciador"
                            icone={<CategoryIcon fontSize="large" />}
                            valorPadrao="Nicho que prefere"
                            controle={setCaegoria}
                        />
                        {categorias.length === 0 ? <span> Preencha esse campo </span> : ""}

                        {/* Usar como componente no futuro */}
                        <div className={styles.Categorias}>
                            <label> Nichos escolhidos </label>
                            <ul>
                                {categorias.map((categoria, index) => (
                                    <li key={index}> {categoria} <CloseOutlinedIcon fontSize="small" onClick={() => removerCategoria(index)} /> </li>
                                ))}
                            </ul>
                        </div>

                        <CampoSwitch
                            titulo="Aceita fora do escolhido?"
                            valor={switchCategoria}
                            controle={setSwitchCaegoria}
                        />

                        <section>
                            <CampoEscolha
                                lista={listaDeIdadesInicio}
                                titulo="Idade, de:"
                                icone={<EventNoteIcon fontSize="large" />}
                                valorPadrao="mínima"
                                controle={setIdadeInicio}
                            />

                            <CampoEscolha
                                lista={listaDeIdadesFim}
                                titulo="até"
                                icone={<EventBusyOutlinedIcon fontSize="large" />}
                                valorPadrao="máxima"
                                controle={setIdadeFim}
                            />
                        </section>
                        {idadeInicio.length === 0 || idadeFim.length === 0 ? <span> Preencha esses campos </span> : ""}
                        <CampoSwitch
                            titulo="Aceita fora do escolhido?"
                            valor={switchIdade}
                            controle={setSwitchIdade}
                        />

                        <CampoEscolha
                            lista={nomesEstados}
                            titulo="Estado"
                            icone={<MapIcon fontSize="large" />}
                            valorPadrao="Lugar de preferência"
                            controle={setEstado}
                        />
                        <div className={styles.ContainerAlertas}>
                            {estado.length === 0 ? <span> Preencha esse campo </span> : ""}

                            <BotaoStepper controle={voltaStep}>
                                Anterior
                            </BotaoStepper>
                        </div>
                    </div> : ''}

                    {step === 2 ? <><button className="btn btn-primary" disabled={genero.length === 0 ||
                        qtdSeguidores.length === 0 || categorias.length === 0 || idadeInicio.length === 0 ||
                        idadeFim.length === 0 || estado.length === 0}>
                        Ir para imagem
                    </button> {genero.length === 0 || qtdSeguidores.length === 0 || categorias.length === 0 ||
                        idadeInicio.length === 0 || idadeFim.length === 0 || estado.length === 0
                        ? <span> Preencha os campos para criar </span> : ""}</> : ''}
                </form>
            </main> : <UsuarioNaoAceitoAnunciante />}
        </>
    );
}