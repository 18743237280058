import "./MenuPainelAnunciante.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArticleIcon from '@mui/icons-material/Article';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import Notificacoes from "../Notificacoes";
import perfil_fake from "./perfil_fake.png";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import autenticaStore from "../../stores/autentica.store";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "../../db/banco";
import INotificacao from "../../types/INotificao";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Modals from "../../utils/modals";
import IAnunciante from "../../types/IAnunciante";
import imageModal from "../../Image/img-modal-expired.png";

interface MenuPainelProps {
    // Inicio
    painel: string,
    toInicio: string,
    // Campanhas
    camapanhasUm: string,
    campanhasDois: string,
    campanhasTres?: string,
    campanhasQuatro?: string,
    toCampanhaUm: string,
    toCampanhaDois: string,
    // Gerenciar Influenciadores
    iconeGerenciarInfluenciadores?: ReactNode,
    textoGerenciarInfluenciadores?: string,
    // Redes sociais
    iconeRedesSociais?: ReactNode,
    textoRedesSocias?: string,
    //
    ehAnunciante: boolean
}

export default function MenuPainelAnunciante({
    painel, toInicio, camapanhasUm, campanhasDois, campanhasTres, campanhasQuatro, toCampanhaUm, toCampanhaDois,
    iconeGerenciarInfluenciadores, textoGerenciarInfluenciadores, iconeRedesSociais, textoRedesSocias, ehAnunciante }
    : MenuPainelProps) {

    // recebe as notificacões
    const [notificacoes, setNotificacoes] = useState<INotificacao[]>([]);
    // obtem o email(serve como id) do anunciante
    const email = autenticaStore.getEmail();
    const [dataCriacao, setDataCriacao] = useState("");
    const [nomeAnunciante, setNomeAnunciante] = useState("");
    const navigate = useNavigate();
    const handleLogout = () => {
        autenticaStore.logout(); // Chame o método logout da AutenticaStore
    };
    // Modal/Pop-up de aviso de plano expirado
    const [abrirPopup, setAbrirPopup] = useState(false);
    const [expirado, setExpirado] = useState(false);
    // banco de dados
    const useCollectionRefNotificacao = collection(db, "notificacao");

    useEffect(() => {
        const obterUsuario = async () => {
            const dataBD = await getDocs(useCollectionRefNotificacao);
            const todasNotificacoes: INotificacao[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as INotificacao);
            const somenteDesseAnunciante = todasNotificacoes.filter(desseAnunciante => desseAnunciante.emailDeQuemVaiReceberNotificacao === email);
            const somenteNaoVistas = somenteDesseAnunciante.filter(naoVistas => naoVistas.visto === false);
            setNotificacoes(somenteNaoVistas);
        };
        obterUsuario();
        // Atualize os dados a cada 5 segundos
        const intervalId = setInterval(obterUsuario, 2000);

        // Limpar o intervalo quando o componente for desmontado
        return () => clearInterval(intervalId);
    }, []);

    // Anunciante    
    const useCollectionRefAnunciante = collection(db, "anunciante");
    // obtem os anunciantes
    useEffect(() => {
        const obterAnunciantes = async () => {
            const dataBD = await getDocs(useCollectionRefAnunciante);
            const todosAnunciantes: IAnunciante[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IAnunciante);
            const esseAnunciante: IAnunciante[] = todosAnunciantes.filter(analise => analise.email === email);

            esseAnunciante.map(dados => {
                const [day, month, year] = dados.dataCriacao.split('/');
                const initialDate = new Date(Number(year), Number(month) - 1, Number(day));
                const oneMonthLater = new Date(initialDate);
                oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

                const today = new Date();
                const diffTime = oneMonthLater.getTime() - today.getTime();
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays < 0 && dados.planoISM === 0) {
                    setExpirado(true);
                } else {
                    setExpirado(false);
                }

                setNomeAnunciante(dados.nomeCompleto);
            })
        };
        obterAnunciantes();
    }, []);

    // Modal
    const handleClose = () => {
        setAbrirPopup(false);
    }

    function verificaPlano() {
        if (expirado) {
            setAbrirPopup(true);
        }
        else {
            navigate(toCampanhaUm);
        }
    }

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary MenuPainel">
            <Modals
                openModal={abrirPopup}
                closeModal={handleClose}
                title={`${nomeAnunciante}, seu período de teste terminou!`}
                description="Por favor, para continuar com acesso total a nossa plataforma, se torne um assinante."    
                cliqueAqui            
                imageSrc={imageModal}
            />
            <div className="container-fluid">
                <Link className="navbar-brand" to={toInicio}> {painel} </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to={toInicio} className="nav-link">
                                <HomeIcon fontSize="large" />
                                Início
                            </Link>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <ArticleIcon fontSize="large" />
                                Campanhas
                            </a>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item text-center" onClick={verificaPlano}> {camapanhasUm} </li>
                                {toCampanhaDois && <li className="dropdown-item text-center"> <Link to={toCampanhaDois}>{campanhasDois} </Link> </li>}
                                {campanhasTres && <>
                                    <li className="dropdown-item text-center"> <Link to="/influenciador/minhas-campanhas"> {campanhasTres} </Link> </li>
                                    <li className="dropdown-item text-center"> <Link to="/influenciador/campanhas-finalizadas"> {campanhasQuatro} </Link> </li>
                                </>}
                            </ul>
                        </li>
                        {/* // */}
                        {campanhasTres ? <li className="nav-item">
                            <Link to="/influenciador/redes-sociais" className="nav-link">
                                {iconeRedesSociais}
                                {textoRedesSocias}
                            </Link>
                        </li> : <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {iconeGerenciarInfluenciadores}
                                {textoGerenciarInfluenciadores}
                            </a>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item text-center"> <Link to="/anunciante/influenciadores-em-analise"> Influenciadores </Link> </li>
                                <li className="dropdown-item text-center"> <Link to="/anunciante/aguardando-confirmacao"> Aguardando confirmação </Link> </li>
                            </ul>
                        </li>}

                        {ehAnunciante && <Link to="/anunciante/carteira" className="nav-link">
                            <WalletOutlinedIcon fontSize="large" />
                            Carteira
                        </Link>}

                        {ehAnunciante ? <li className="nav-item">
                            <Link to="/anunciante/nota-fiscal" className="nav-link">
                                <ReceiptIcon fontSize="large" />
                                Nota fiscal
                            </Link>
                        </li> : <li className="nav-item">
                            <Link to="/influenciador/fatura" className="nav-link">
                                <PaymentOutlinedIcon fontSize="large" />
                                Fatura
                            </Link>
                        </li>}

                        <li className="nav-item">
                            <Link to="/login" className="nav-link" onClick={handleLogout}>
                                <ExitToAppIcon fontSize="large" />
                                Sair
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {notificacoes.length > 0 ? <NotificationsActiveIcon fontSize="large" /> : <NotificationsIcon fontSize="large" />}
                                Notificações
                            </a>
                            <ul className="dropdown-menu">
                                {notificacoes.length > 0 ? <Notificacoes lista={notificacoes} /> : <p className="nenhumaNot"> Nenhuma nova notificação no momento. </p>}
                                {notificacoes.length === 0 ? <hr className="hr" /> : ""}

                                <Link to="/anunciante/notificacoes" className="verTodas"> <p> Ver todas notificações </p> </Link>

                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <SupportAgentIcon fontSize="large" />
                                Suporte
                            </a>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item text-center"> <Link to="/faq"> Ver FAQ </Link> </li>
                                <li className="dropdown-item text-center"> <Link to={ehAnunciante ? "/anunciante/abrir-chamado" : "/influenciador/abrir-chamado"}> Abrir chamado </Link> </li>
                                <li className="dropdown-item text-center"> <Link to={ehAnunciante ? "/anunciante/meus-chamados" : "/influenciador/meus-chamados"}> Meus chamados </Link> </li>
                            </ul>
                        </li>

                        <div className="Perfil">
                            <Link to={ehAnunciante ? "/anunciante/perfil" : "/influenciador/perfil"}> <img src={perfil_fake} alt="imagem de perfil" /> </Link>
                        </div>
                    </ul>
                </div>

            </div>
        </nav>
    )
}