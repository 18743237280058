import Player from 'react-lottie-player';
import animationData from '../Dot/Animation.json';
import styles from "./Dot.module.css";

const Dot = () => {
    return (
        <div className={styles.dotContainer}>
            <Player
                loop
                animationData={animationData}
                play
                style={{ width: 300, height: 300 }}
            />
        </div>
    );
};

export default Dot;


