export const listaDeNichos = [
    'Outros',
    'Apostas',
    'Automóveis',
    'Bebida',
    'Beleza',
    'Decoração',
    'Educação',
    'Empreendorismo',
    'Entertenimento',
    'Esporte',
    'Finanças',
    'Fitness',
    'Games/Geek',
    'Gastronomia',
    'Infantil',
    'Lifestyle',
    'Livros',
    'Moda',
    'Moda masculina',
    'Música',
    'Pets',
    'Veganos',
    'Viagem',
    'Vida saudável'
]