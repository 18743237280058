import React from 'react';

interface FormatarDinheiroProps {
  price: number | string;
  currency: string;
}

const FormatarDinheiro: React.FC<FormatarDinheiroProps> = ({ price, currency }) => {
  // Função para escolher o símbolo correto da moeda
  const getCurrencySymbol = (currency: string) => {
    switch (currency) {
      case 'BRL':
        return 'R$';
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return currency;
    }
  };
  // Função para formatar o preço com 2 dígitos após a vírgula
  const formatPrice = (price: number | string) => {
    const numericPrice = typeof price === 'number' ? price : parseFloat(price);
    return numericPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <span>
      {getCurrencySymbol(currency)}{formatPrice(price)}
    </span>
  );
};

export default FormatarDinheiro;
