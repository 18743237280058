import { useEffect } from "react";
import { sendEmail } from "../../../utils/functions";

interface VerificaPLanoProps {
    plano: number,
    dataCriacao: string,
    planoTrocado: string,
    email: string,
    nome: string
}

export default function VerificaPLano({ plano, dataCriacao, planoTrocado, email, nome }: VerificaPLanoProps) {

    useEffect(() => {
        const [day, month, year] = dataCriacao.split('/');
        const initialDate = new Date(Number(year), Number(month) - 1, Number(day));
        const oneMonthLater = new Date(initialDate);
        oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

        const today = new Date();
        const diffTime = oneMonthLater.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        const assuntoEmail = `${nome}, faltam 7 dias para finalizar o período de teste em nossa plataforma`;
        const mensagem = `Entre em nossa plataforma e siga as instruções para ser um assinante e voltar ter acesso total ao ISM`;

        if (diffDays === 7) sendEmail(email, assuntoEmail, mensagem);
        
    }, []);

    return (
        <h6>
            {plano === 0 ?
                (() => {
                    const [day, month, year] = dataCriacao.split('/');
                    const initialDate = new Date(Number(year), Number(month) - 1, Number(day));
                    const oneMonthLater = new Date(initialDate);
                    oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

                    const today = new Date();
                    const diffTime = oneMonthLater.getTime() - today.getTime();
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Converte milissegundos para dias

                    return diffDays > 0 ? `${diffDays} dias restantes da sua versão Trial.` : <span> Plano expirado. </span>;
                })() :
                plano === 1 ? "Assinante Actionpay" : `Assinante desde: ${planoTrocado}`}
        </h6>
    );
}