import React, { useEffect, useState } from 'react';
import { ITransactionID } from '../../../../types/ITransactionID';
import { formatarData } from '../functions';
import FormatarDinheiro from '../FormatarDinheiro';
import { obterTodosInfluenciadores } from '../../../../utils/db';
import IInfluenciador from '../../../../types/IInfluenciador';
import styles from "./TabelaTransacao.module.css"

interface TabelaTransacaoProps {
    transactionIDPaginados: ITransactionID[];
    tipoUsuario: string
}

const TabelaTransacao: React.FC<TabelaTransacaoProps> = ({ transactionIDPaginados, tipoUsuario }) => {
    // recebe o influenciador
    const [Influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    // Carrega dados dos influenciadores
    useEffect(() => {
        const carregarInfluenciadores = async () => {
            const todosInfluenciadores = await obterTodosInfluenciadores();
            setInfluenciadores(todosInfluenciadores)
        };
        carregarInfluenciadores();
    }, []);

    return (
        <div className={styles.TabelaTransacao}>
            <table className="table table-light table-striped">
                <thead>
                    <tr>
                        <th>ID da transação</th>
                        <th>Data e hora</th>
                        <th>Status</th>
                        <th>Valor da transação</th>
                        <th>Comissão</th>
                        {tipoUsuario !== "influ" && <th>Influenciador</th>}
                    </tr>
                </thead>
                <tbody>
                    {transactionIDPaginados.map((action, index) => (
                        <tr key={action.uniqueid} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <td data-label="ID da transação">{action.apid}</td>
                            <td data-label="Data e hora">{formatarData(action.date)}</td>
                            <td data-label="Status"
                                className={`${action.status.id === 400 ? 'desativadoMobile' : action.status.id === 300
                                    ? 'processando'
                                    : 'ativoMobile'}`}
                            >
                                {action.status.id === 400
                                    ? 'Rejeitada'
                                    : action.status.id === 300
                                        ? 'Em espera'
                                        : 'Aprovada'}
                            </td>
                            <td data-label="Valor da transação">
                                <FormatarDinheiro price={action.price} currency={action.priceCurrency} />
                            </td>
                            <td data-label="Comissão">
                                <FormatarDinheiro price={action.payment} currency={action.priceCurrency} />
                            </td>
                            {tipoUsuario !== "influ" && (
                                <td data-label="Influenciador">
                                    {(() => {
                                        const influenciador = Influenciadores.find(
                                            influ => influ.codigoActionpayInfluenciador === Number(action.subId1)
                                        );
                                        return influenciador ? (
                                            <span>{influenciador.nomeCompleto}</span>
                                        ) : 'Nome não encontrado';
                                    })()}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};

export default TabelaTransacao;
