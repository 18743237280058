import styles from "./ConteudoPopUpMini.module.css";
import Face6Icon from '@mui/icons-material/Face6';
import InstagramIcon from '@mui/icons-material/Instagram';

interface ConteudoPopMiniProps {
    idInfluenciador: string;
    linkRedeSocial: string;
    tipoUsuario: string;
}

export default function ConteudoPopUpMini({ idInfluenciador, linkRedeSocial, tipoUsuario }: ConteudoPopMiniProps) {
    return (
        <div className={styles.ConteudoPopUpMini}>
            {tipoUsuario === "mod" ? <a href={`/moderador/ver-perfil-influenciador?id=${idInfluenciador}`} target="_blank" rel="noopener noreferrer">
                <span> <Face6Icon /> <label> Perfil do influenciador </label> </span>
            </a> : <a href={`/anunciante/perfil-influenciador?id=${idInfluenciador}`} target="_blank" rel="noopener noreferrer">
                <span> <Face6Icon /> <label> Perfil do influenciador </label> </span>
            </a>}

            {linkRedeSocial !== "www.exemplo.com" && <a href={linkRedeSocial} target="_blank" rel="noopener noreferrer">
                <span> <InstagramIcon /> <label> Rede social do influenciador </label> </span>
            </a>}
        </div>
    )
}